import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { useActionChoices } from "stores";
import { ACTION_STATUS } from "constants/actionStatus";
import { transformObjectValues } from "utils/transformObjectKeys";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { OPERATIONS } from "constants/actions";

export const useAnalysisFields = (viewMode) => {
  const { values, setFieldValue } = useFormikContext();
  const { actions, isSpecificOperation } = useActionChoices();
  const [analysisFields, setAnalysisFields] = useState();

  useEffect(() => {
    const opId = values?.diagnoseAction?.operationTypeId;
    if (!opId || values?.status === ACTION_STATUS.suggested) {
      setAnalysisFields();
      return;
    }

    const isNewAnalysis =
      viewMode === ACTION_VIEW_MODE.new ||
      (viewMode === ACTION_VIEW_MODE.edit &&
        values?.initialStatus !== ACTION_STATUS.performed);

    const isSoilSampling = isSpecificOperation(
      OPERATIONS.DIAGNOSE.SOIL_SAMPLING,
      opId
    );
    if (isSoilSampling) {
      if (!isNewAnalysis) {
        setAnalysisFields(values.diagnoseAction?.analysis);
        return;
      }
      const analysis = transformObjectValues(
        actions.diagnose?.operations[1]?.operationUnits,
        (item) => ({
          ...item,
          quantityUnit: item?.quantityUnits?.[0],
        })
      );
      setFieldValue("diagnoseAction.analysis", analysis);
      setAnalysisFields(analysis);
      return;
    }
    const isLeafAnalysis = isSpecificOperation(
      OPERATIONS.DIAGNOSE.LEAF_ANALYSIS,
      opId
    );
    if (isLeafAnalysis) {
      if (!isNewAnalysis) {
        setAnalysisFields(values.diagnoseAction?.analysis);
        return;
      }
      const analysis = transformObjectValues(
        actions.diagnose?.operations[2]?.operationUnits,
        (item) => ({
          ...item,
          quantityUnit: item?.quantityUnits?.[0],
        })
      );
      setFieldValue("diagnoseAction.analysis", analysis);
      setAnalysisFields(analysis);
      return;
    }
    setAnalysisFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.diagnoseAction?.operationTypeId,
    values?.status,
    viewMode,
    actions.diagnose?.operations,
  ]);

  return { analysisFields };
};
