import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { FormHelperText } from "@mui/material";
import { ClearInput } from "components";
import DateField from "./DateField";

const DateRangeField = ({
  startLabel,
  endLabel,
  startPlaceholder,
  endPlaceholder,
  startDefaultValue,
  endDefaultValue,
  startValue,
  endValue,
  startName,
  endName,
  startError,
  endError,
  isDisabled,
  isViewMode,
  isClearable,
  seperator,
  onChange,
  helperText,
  inputFormat,
  fieldClassName,
  fieldStyle,
  minDate,
  maxDate,
}) => {
  const [dateRange, setDateRange] = useState({
    start: startDefaultValue || startValue || null,
    end: endDefaultValue || endValue || null,
  });
  const [startMonth, setStartMonth] = useState(moment());
  const [endMonth, setEndMonth] = useState(moment());

  const handleStartChange = useCallback(
    (date) => {
      const shouldNotEraseEndDate =
        dateRange.end == null ||
        !moment.isMoment(date) ||
        moment(date).isBefore(dateRange.end);

      setDateRange((prev) => ({
        ...prev,
        start: date,
        end: shouldNotEraseEndDate ? prev.end : null,
      }));

      if (date != null) {
        onChange?.({
          start: date,
          end: shouldNotEraseEndDate ? dateRange.end : null,
        });
      }
    },
    [dateRange?.end, onChange]
  );

  const handleEndChange = useCallback(
    (time) => {
      setDateRange((prev) => ({
        ...prev,
        end: time,
      }));

      if (time != null) {
        onChange?.({
          start: dateRange.start,
          end: time,
        });
      }
    },
    [dateRange?.start, onChange]
  );

  const handleClearStart = useCallback(() => {
    setDateRange((prev) => ({
      ...prev,
      start: null,
    }));
    onChange?.({
      start: null,
      end: dateRange.end,
    });
  }, [dateRange?.end, onChange]);

  const handleClearEnd = useCallback(() => {
    setDateRange((prev) => ({
      ...prev,
      end: null,
    }));
    onChange?.({
      start: dateRange.start,
      end: null,
    });
  }, [dateRange?.start, onChange]);

  const handleStartMonth = useCallback((monthMoment) => {
    if (!monthMoment) return;
    setStartMonth(monthMoment);
  }, []);
  const handleEndMonth = useCallback((monthMoment) => {
    if (!monthMoment) return;
    setEndMonth(monthMoment);
  }, []);

  useEffect(() => {
    setDateRange((prev) => ({ ...prev, start: startValue }));
  }, [startValue]);

  useEffect(() => {
    setDateRange((prev) => ({ ...prev, end: endValue }));
  }, [endValue]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: !seperator ? "8px" : "0px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
          <DateField
            name={startName}
            label={startLabel}
            placeholder={startPlaceholder}
            value={dateRange.start}
            defaultValue={startDefaultValue}
            defaultMonth={endMonth}
            isDisabled={isDisabled}
            isViewMode={isViewMode}
            isError={Boolean(startError)}
            inputFormat={inputFormat}
            className={fieldClassName}
            style={fieldStyle}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleStartChange}
            onMonthChange={handleStartMonth}
          />
          {isClearable && (
            <ClearInput value={dateRange.start} onClear={handleClearStart} />
          )}
        </div>
        {seperator}
        <div style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
          <DateField
            name={endName}
            label={endLabel}
            placeholder={endPlaceholder}
            value={dateRange.end}
            defaultValue={endDefaultValue}
            defaultMonth={startMonth}
            isDisabled={isDisabled}
            isError={Boolean(endError)}
            isViewMode={isViewMode}
            inputFormat={inputFormat}
            className={fieldClassName}
            style={fieldStyle}
            minDate={dateRange.start}
            maxDate={maxDate}
            onChange={handleEndChange}
            onMonthChange={handleEndMonth}
          />
          {isClearable && (
            <ClearInput value={dateRange.end} onClear={handleClearEnd} />
          )}
        </div>
      </div>
      <FormHelperText error={Boolean(startError || endError)}>
        {helperText}
      </FormHelperText>
    </div>
  );
};

export default DateRangeField;
