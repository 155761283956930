import { styled } from "@mui/material";
import GlobalSidebarsContainer from "../sidebars/global-container/GlobalSidebarsContainer";

const Container = styled("div")(({ theme }) => ({
  height: "100vh",
  display: "flex",
  position: "relative",
  marginLeft: theme.width.navbar,
  [theme.breakpoints.down("md")]: {
    marginLeft: theme.mobile.margin.default,
  },
}));
const PageWrapper = ({ children }) => {
  return (
    <Container>
      <GlobalSidebarsContainer />
      {children}
    </Container>
  );
};

export default PageWrapper;
