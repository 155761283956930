/* eslint-disable no-param-reassign */
export const transformObjectKeys = (obj, transform) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    newObj[transform(key)] = obj[key];
  });
  return newObj;
};

export const deepTransformKeys = (obj, transform, exclude = []) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepTransformKeys(item, transform));
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const finalKey = exclude.includes(key) ? key : transform(key);
      return [finalKey, deepTransformKeys(value, transform)];
    })
  );
};

export const transformObjectValues = (obj, transform) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, transform(value)])
  );
};
