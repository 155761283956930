import { isEn } from "i18next-config";

const transformInformation = ({ information = {}, parentIdPath }) => {
  const infoArray = Object.values(information);
  return infoArray.map((info) => {
    if (info.taxonomyId) {
      return {
        value: info.taxonomyId,
        label: isEn ? info.taxonomyNameEn : info.taxonomyNameGr,
        children: transformInformation({
          information: info.crops,
          isEn,
          parentIdPath: [info.taxonomyId],
        }),
        parentIdPath: [],
      };
    } else if (info.varieties) {
      return {
        value: info.cropId,
        label: isEn ? info.cropNameEn : info.cropNameGr,
        children: transformInformation({
          information: info.varieties,
          isEn,
          parentIdPath: [...parentIdPath, info.cropId],
        }),
        parentIdPath,
      };
    } else {
      return {
        value: info.varietyId,
        label: isEn ? info.varietyNameEn : info.varietyNameGr,
        parentIdPath,
      };
    }
  });
};

export class CropsDto {
  static setup(data) {
    const cropsInfo = transformInformation({ information: data });
    return cropsInfo;
  }
}
