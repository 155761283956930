import "./action-modal.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { varietyFullName } from "utils/extractInfoFromResponses";
import { useActionModalGetContext } from "context/ActionModalProvider";
import { FETCH_STATE } from "constants/fetchState";
import Modal from "../Modals/Modal";
import Loader from "../Loader/Loader";
import NoContentFound from "../NoContentFound/NoContentFound";
import ActionReadyContent from "./content/ActionReadyContent";
import { useMemo } from "react";
import { useActionChoices } from "stores";

const actionModalBreakpoint = "@media (max-width:1000px)";

const ActionModal = ({ isOpen, onClose, selectedFields }) => {
  const isSmallScreen = useMediaQuery(actionModalBreakpoint);
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchState, errorMessage } = useActionModalGetContext();
  const { fetchState: actionsFetchState } = useActionChoices();
  const isReady =
    fetchState === FETCH_STATE.SUCCESS && actionsFetchState.isSuccess;
  const isLoading =
    fetchState === FETCH_STATE.LOADING || actionsFetchState.isPending;

  const handleClose = () => {
    const params = new URLSearchParams(location.search);
    if (params.has("actionId")) {
      params.delete("actionId");
      navigate(
        location.pathname + params.toString() ? "?" + params.toString() : ""
      );
    }
    onClose?.();
  };

  const title = useMemo(() => {
    if (!selectedFields?.length) return;

    const fieldsName =
      selectedFields.length === 1
        ? selectedFields[0]?.name
        : `${selectedFields.length} ${t("Global.Field", { count: selectedFields.length })}`;

    return `${fieldsName} | ${varietyFullName(isEn, selectedFields[0], { splitter: " / " })}`;
  }, [t, isEn, selectedFields]);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className={`action-modal action-modal-modal ${
        isSmallScreen ? "small-screen" : ""
      }`}
      onClose={handleClose}
    >
      {isLoading && <Loader size={50} />}
      {fetchState === FETCH_STATE.ERROR && (
        <NoContentFound text={errorMessage} />
      )}
      {isReady && (
        <ActionReadyContent
          selectedFields={selectedFields}
          onClose={handleClose}
        />
      )}
    </Modal>
  );
};

export default ActionModal;
