import { useMemo } from "react";
import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const ClearInput = ({ value, onClear, style }) => {
  const isShown = useMemo(() => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return Boolean(value);
  }, [value]);

  return (
    <IconButton
      onClick={onClear}
      size="small"
      sx={{
        height: "1rem",
        width: "1rem",
        padding: 0,
        visibility: isShown ? "visible" : "hidden",
        ...style,
      }}
    >
      <Cancel sx={{ width: "100%", height: "100%" }} />
    </IconButton>
  );
};

export default ClearInput;
