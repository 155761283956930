import { isEn } from "i18next-config";
import { sortAlphabetically } from "utils/utils";

export class EquipmentsDto {
  static setup(data) {
    const machineriesTranslated = data.map((machinery) => {
      const { nameEn, nameGr, ...rest } = machinery;
      return {
        ...rest,
        name: isEn ? nameEn : nameGr,
      };
    });
    const machineries = sortAlphabetically(machineriesTranslated, "name");
    return machineries;
  }
}
