export const squareMetersToStremmata = (squareMeters) => {
  return squareMeters === 0 ? 0 : (squareMeters / 1000).toFixed(2);
};
export const squareMetersToAcre = (squareMeters) => {
  return squareMeters === 0 ? 0 : (squareMeters / 4046.856).toFixed(2);
};
export const squareMetersToHectares = (squareMeters) => {
  return squareMeters === 0 ? 0 : (squareMeters / 10000).toFixed(2);
};
export const hectaresToSquareMeters = (hectares) => {
  return hectares * 10000;
};
export const hectaresToStremmata = (hectares) => {
  return (hectares * 10).toFixed(2);
};
export const hectaresToAcre = (hectares) => {
  return (hectares * 2.47105).toFixed(2);
};

/**
 *
 * @param {*} t
 * @param {boolean} isEn
 * @param {number} units
 * @param {Object} options
 * @param {'squareMeters' | 'hectares'} options.from
 * @param {'stremmata' | 'hectares'} options.to
 * @returns {string}
 */
export const fieldDimensionsRead = (
  t,
  isEn,
  units,
  { from = "squareMeters", to = "stremmata" } = {
    from: "squareMeters",
    to: "stremmata",
  }
) => {
  if (isEn) {
    if (from === "squareMeters") {
      if (to === "stremmata") {
        return t("Global.FieldDimension.Acre", {
          dimension: squareMetersToAcre(units),
        });
      }
      return t("Global.FieldDimension.Hectares", {
        dimension: squareMetersToHectares(units),
      });
    }
    return t("Global.FieldDimension.Acre", {
      dimension: hectaresToAcre(units),
    });
  }
  if (from === "squareMeters") {
    if (to === "stremmata") {
      return t("Global.FieldDimension.Stremmata", {
        dimension: squareMetersToStremmata(units),
      });
    }
    return t("Global.FieldDimension.Hectares", {
      dimension: squareMetersToHectares(units),
    });
  }
  return t("Global.FieldDimension.Stremmata", {
    dimension: hectaresToStremmata(units),
  });
};
