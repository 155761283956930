import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { elGR } from "@mui/x-data-grid/locales";
import { styled } from "@mui/material";

const DataGridStyled = styled(DataGrid)({
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-overlayWrapperInner": {
    background: "rgba(0, 0, 0, 0.2)",
  },
});

export default function DataTableCustom({
  columns = [],
  rows = [],
  initialState = {},
  styles = {},
  isLoading,
  width = "100%",
  height = "100%",
  setSelectedRows,
}) {
  const { i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const handleSelection = (ids, details) => {
    const gridApi = details.api;
    const rowsMap = gridApi?.getSelectedRows();
    if (rowsMap) {
      const rows = Array.from(rowsMap.values());
      setSelectedRows(rows);
    } else {
      setSelectedRows(ids);
    }
  };

  return (
    <div style={{ width, height }}>
      <DataGridStyled
        localeText={
          isEn ? {} : elGR.components.MuiDataGrid.defaultProps.localeText
        }
        loading={isLoading}
        rows={rows}
        columns={columns}
        initialState={initialState}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        checkboxSelection
        onRowSelectionModelChange={handleSelection}
        style={styles}
      />
    </div>
  );
}
