import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useActionChoices } from "stores";
import { SmallText, Title } from "components";
import { useActionModalGetContext } from "context/ActionModalProvider";
import { FormNumberInput, FormSelectInput } from "form";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { OPERATIONS } from "constants/actions";
import DateSpan from "../date-span/DateSpan";
import DetailSection from "../DetailSection";
import AnalysisResults from "../analysis-results/AnalysisResults";
import { useAnalysisFields } from "./useAnalysisFields";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";

const DISABLED_BULK_OPERATIONS = [
  OPERATIONS.DIAGNOSE.SOIL_SAMPLING,
  OPERATIONS.DIAGNOSE.LEAF_ANALYSIS,
  OPERATIONS.DIAGNOSE.INSECT_MONITORING,
];
const DiagnosePerformed = ({ viewMode, fieldInfo }) => {
  const { t } = useTranslation();
  const { actions, isSpecificOperation, getOperationById } = useActionChoices();
  const { isBulk } = useActionModalGetContext();
  const { values, setFieldValue } = useFormikContext();

  const operationOptions = useMemo(() => {
    if (isBulk) {
      return actions.diagnose?.operations.map((op) => {
        const opName = getOperationById(op.operationTypeId);
        const shouldBeDisabled = DISABLED_BULK_OPERATIONS.includes(opName);

        return {
          value: op.operationTypeId,
          label: op.operationTypeName,
          disabled: shouldBeDisabled,
        };
      });
    } else {
      return actions.diagnose?.operations.map((op) => ({
        value: op.operationTypeId,
        label: op.operationTypeName,
      }));
    }
  }, [actions, getOperationById, isBulk]);

  const status = ACTION_STATUS.performed;
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  const { analysisFields } = useAnalysisFields(viewMode);

  const isInsectWatchingOperation = useMemo(() => {
    return isSpecificOperation?.(
      OPERATIONS.DIAGNOSE.INSECT_MONITORING,
      values?.diagnoseAction?.operationTypeId
    );
  }, [isSpecificOperation, values?.diagnoseAction?.operationTypeId]);

  const checkIfDisabledOperation = useCallback(
    (operationTypeId) => {
      return (
        isBulk &&
        DISABLED_BULK_OPERATIONS.includes(getOperationById(operationTypeId))
      );
    },
    [getOperationById, isBulk]
  );

  useEffect(() => {
    const isDisabledOperationChosen = checkIfDisabledOperation(
      values?.diagnoseAction?.operationTypeId
    );
    if (isDisabledOperationChosen) {
      setFieldValue("diagnoseAction.operationTypeId", "");
    }
  }, [
    values?.diagnoseAction?.operationTypeId,
    checkIfDisabledOperation,
    setFieldValue,
  ]);

  return (
    <div className="action-modal-card action-details-box">
      <div className="details-half-width">
        <Title title={t("ActionModal.Details.Performed")} />
        <DateSpan viewMode={viewMode} status={status} />
        <DetailSection title={t("ActionModal.DiagnoseType")} isRequired>
          <FormSelectInput
            name="diagnoseAction.operationTypeId"
            shouldHaveErrorBehaviorAfterSubmit
            options={operationOptions}
            isViewMode={isViewMode}
            helperText={
              isBulk ? "! " + t("ActionModal.DiagnoseBulkWarning") : ""
            }
          />
        </DetailSection>
      </div>
      {isInsectWatchingOperation && (
        <DetailSection
          title={t("ActionModal.Insects.Title")}
          isRequired={!isViewMode}
        >
          <FormNumberInput
            style={{ width: "25%" }}
            name="diagnoseAction.quantity"
            endAdornment={t("ActionModal.Insects.Units")}
          />
          <SmallText className="field-description">
            {t("ActionModal.Insects.Description")}
          </SmallText>
        </DetailSection>
      )}
      {analysisFields ? (
        <DetailSection title={t("ActionModal.AnalysisResult.Title")}>
          <AnalysisResults
            field={fieldInfo}
            analysisFields={analysisFields}
            isViewMode={isViewMode}
          />
        </DetailSection>
      ) : (
        <div className="details-half-width">
          <ActionModalDescriptionSection
            actionBaseName="diagnoseAction"
            isViewMode={isViewMode}
          />
        </div>
      )}
    </div>
  );
};

export default DiagnosePerformed;
