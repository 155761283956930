import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { snakeToCamel } from "../utils/camelSnakeTransformation";
import { deepTransformKeys } from "../utils/transformObjectKeys";
import { limits_static } from "./providers/limits";

const WateringUsageContext = createContext({
  limits: [],
});

const GeneralAgroProvider = ({ children }) => {
  const [wateringLimits, setWateringLimits] = useState();

  const getWateringLimits = useCallback(
    async (cropId) => {
      if (wateringLimits) {
        if (cropId) {
          return wateringLimits[cropId];
        }
        return wateringLimits;
      } else {
        try {
          // const { data } = await api.getWaterLimits();
          const data = deepTransformKeys(limits_static, snakeToCamel);
          const newLimits = {};
          data?.forEach((cropLimit) => {
            const cropId = cropLimit?.variety?.cropId;
            if (cropId) {
              newLimits[cropId] = {
                descriptionGr: cropLimit.descriptionGr,
                descriptionEn: cropLimit.descriptionEn,
              };
            }
          });
          setWateringLimits(newLimits);
          if (cropId) {
            return newLimits[cropId];
          }
          return newLimits;
        } catch (err) {
          console.error(err);
          return {};
        }
      }
    },
    [wateringLimits]
  );

  const wateringUsageValues = useMemo(
    () => ({
      getWateringLimits,
    }),
    [getWateringLimits]
  );

  return (
    <WateringUsageContext.Provider value={wateringUsageValues}>
      {children}
    </WateringUsageContext.Provider>
  );
};

const useWateringUsageContext = () => {
  const context = useContext(WateringUsageContext);

  if (!context) {
    throw new Error(
      "useWateringUsageContext must be used within a WateringUsageProvider"
    );
  }
  return context;
};

export { GeneralAgroProvider, useWateringUsageContext };
