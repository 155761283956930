import { useTranslation } from "react-i18next";
import { Modal, Tabs } from "components";
import { useTabs } from "hooks/useTabs";
import { useFieldInfoModalContext } from "context/FieldInfoModalProvider";
import { FETCH_STATE } from "constants/fetchState";
import FieldCamvioInfo from "./camvio/FieldCamvioInfo";
import FieldOpekepeInfo from "./opekepe/FieldOpekepeInfo";
import Layout from "./common/FieldInfoModalLayout";

const INFO_TABS_VALUES = {
  camvio: "camvio",
  opekepe: "opekepe",
};
const INFO_TABS = [
  {
    value: INFO_TABS_VALUES.camvio,
    label: "Camvio",
  },
  {
    value: INFO_TABS_VALUES.opekepe,
    label: "ΟΠΕΚΕΠΕ",
  },
];

const FieldInfoModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const { tabs, activeTab, setActiveTab } = useTabs(INFO_TABS, "camvio");
  const { actionState, field } = useFieldInfoModalContext();

  const handleClose = () => {
    if (actionState !== FETCH_STATE.LOADING && typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t("FieldInfo.Title")}
      style={{
        height: "750px",
        minWidth: "500px",
        maxWidth: "800px",
      }}
      onClose={handleClose}
    >
      <Layout.ModalInner>
        {field?.isOpekepeConnected && (
          <Tabs
            tabs={tabs}
            value={activeTab}
            setValue={setActiveTab}
            variant="underline"
            onChange={setActiveTab}
          />
        )}
        {activeTab === INFO_TABS_VALUES.camvio && <FieldCamvioInfo />}
        {activeTab === INFO_TABS_VALUES.opekepe && <FieldOpekepeInfo />}
      </Layout.ModalInner>
    </Modal>
  );
};

export default FieldInfoModal;
