export class LanguagesDto {
  static setup(data) {
    const languages = data?.map((lan) => ({
      id: lan.id,
      lan: lan.languageMacro,
      labelKey: `Language.${lan.languageMacro}`,
    }));
    return languages;
  }
}
