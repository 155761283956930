import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { SelectorNew } from "components";
import { useSelectedFieldContext } from "context/SelectedFieldProvider";

const BiocyclePeriodSelector = ({ onChange }) => {
  const { biocycles } = useSelectedFieldContext();
  const isInitialized = useRef(false);

  const biocycleOptions = useMemo(() => {
    const options =
      biocycles.map((cycle) => ({
        value: cycle.id,
        label: cycle.name,
      })) || [];
    const isLatestBiocycleOver = moment().isAfter(
      moment(biocycles[0]?.actualEnd)
    );
    if (!biocycles.length || isLatestBiocycleOver) {
      const currentPeriod = {
        value: "current",
        label: new Date().getFullYear(),
      };
      return [currentPeriod, ...options];
    }
    return options;
  }, [biocycles]);

  const [selected, setSelected] = useState(biocycleOptions[0].value);

  const handleDateChange = useCallback(
    (e) => {
      const newCycleId = e.target.value;
      setSelected(newCycleId);

      const dateRange = { start: "", end: "" };
      if (selected === "current") {
        const latestBiocycle = biocycles?.length ? biocycles[0] : undefined;
        dateRange.start = latestBiocycle
          ? moment(latestBiocycle.actualEnd).toISOString()
          : moment().add(-1, "year").toISOString();
        dateRange.end = moment().toISOString();
        onChange(dateRange);
        return;
      }
      const biocycle = biocycles.find((cycle) => cycle.id === selected);
      dateRange.start = moment(biocycle.actualStart).toISOString();
      dateRange.end = moment(biocycle.actualEnd).toISOString();
      onChange(dateRange);
    },
    [biocycles, onChange, selected]
  );

  useEffect(() => {
    if (isInitialized.current) {
      return;
    }
    const initiallySelected = biocycleOptions[0]?.value;
    const dateRange = { start: "", end: "" };
    if (initiallySelected === "current") {
      const latestBiocycle = biocycles.length ? biocycles[0] : undefined;
      dateRange.start = latestBiocycle
        ? moment(latestBiocycle.actualEnd).toISOString()
        : moment().startOf("year").toISOString();
      dateRange.end = moment().toISOString();
      onChange(dateRange);
      return;
    }
    const biocycle = biocycles[0];
    dateRange.start = moment(biocycle?.actualStart).toISOString();
    dateRange.end = moment(biocycle?.actualEnd).toISOString();
    onChange(dateRange);

    isInitialized.current = true;
  }, [selected, biocycles, onChange, biocycleOptions]);

  return (
    <SelectorNew
      options={biocycleOptions}
      value={selected}
      onChange={handleDateChange}
      isFullWidth={false}
      style={{ width: "150px" }}
    />
  );
};

export default BiocyclePeriodSelector;
