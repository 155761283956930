import { isEn } from "i18next-config";
import { sortAlphabetically } from "utils/utils";

export class WateringMethodsDto {
  static setup(data) {
    const wateringMethods = data?.map((method) => {
      const {
        wateringCategoryGr,
        wateringCategoryEn,
        wateringDescriptionGr,
        wateringDescriptionEn,
        ...rest
      } = method;
      return {
        ...rest,
        category: isEn ? wateringCategoryEn : wateringCategoryGr,
        description: isEn ? wateringDescriptionEn : wateringDescriptionGr,
      };
    });
    const sortedWateringMethods = sortAlphabetically(
      wateringMethods,
      "category"
    );

    return sortedWateringMethods;
  }
}
