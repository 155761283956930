import { createSearchParams } from "utils/createSearchParams";
import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";
import { NewsFeedDTO } from "api/dtos/NewsFeedDto";

/**
 *
 * @param {*} filters
 * @param {string} filters.startDate
 * @param {string} filters.endDate
 * @param {number} filters.page
 * @param {number} filters.pageSize
 * @param {Array<string>} filters.categories
 * @param {Array<string>} filters.areas
 * @param {Array<string>} filters.crops
 * @returns
 */
const getArticles = async (filters = {}) => {
  const transformedFilters = NewsFeedDTO.filterParams(filters);
  const params = createSearchParams(
    { ...transformedFilters, featured: false },
    ["geoGRNuts2"]
  );
  try {
    const res = await axiosInstance.get(`${URLS.newsFeed}/private/`, {
      params,
    });
    const articles = await NewsFeedDTO.articles(res.data);
    return { data: articles };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getFeaturedArticles = async () => {
  const params = {
    featured: true,
    page_size: 10,
  };
  try {
    const res = await axiosInstance.get(`${URLS.newsFeed}/private/`, {
      params,
    });
    const featured = await NewsFeedDTO.featured(res.data);
    return { data: featured };
  } catch (error) {
    throw error;
  }
};

const getNewsFeedFilters = async () => {
  try {
    const res = await axiosInstance.get(`${URLS.newsFeed}/types/`);
    const filters = NewsFeedDTO.filters(res.data);
    return { data: filters };
  } catch (error) {
    throw error;
  }
};

export const newsFeedApi = {
  fetchArticles: getArticles,
  fetchFeatured: getFeaturedArticles,
  fetchFilters: getNewsFeedFilters,
};
