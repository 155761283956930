import { FieldInfoModalProvider } from "context/FieldInfoModalProvider";
import FieldInfoModal from "./FieldInfoModal";

const FieldInfoModalContainer = ({
  id,
  isOpen,
  onClose,
  allow,
  onEditSuccess,
  onCancelSuccess,
  onDeleteSuccess,
}) => {
  return (
    <FieldInfoModalProvider
      id={id}
      allow={allow}
      onEditSuccess={onEditSuccess}
      onCancelSuccess={onCancelSuccess}
      onDeleteSuccess={onDeleteSuccess}
    >
      <FieldInfoModal isOpen={isOpen} onClose={onClose} />
    </FieldInfoModalProvider>
  );
};

export default FieldInfoModalContainer;
