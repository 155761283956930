import { BiocycleDto } from "api/dtos/BiocycleDto";
import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getBiocyclePlanForWeatherAnalyticData = (id) => {
  return axiosInstance(`${URLS.externalRequests}/seasons/${id}/analytics/`);
};

const getBiocyclePlanForCarbonFootprintAndWaterConsumption = async (
  fieldId
) => {
  try {
    const res = await axiosInstance(`${URLS.externalRequests}/seasons/`, {
      params: {
        field_id: fieldId,
      },
    });
    const bioCycles = BiocycleDto.setup(res.data);
    return { ...res, data: bioCycles };
  } catch (error) {
    if (error.response?.status === 404) {
      return { data: [] };
    }
    console.log(error);
    throw error;
  }
};

export const biocycleApi = {
  getBiocyclePlanForWeatherAnalyticData: getBiocyclePlanForWeatherAnalyticData,
  fetchCycles: getBiocyclePlanForCarbonFootprintAndWaterConsumption,
};
