import { create } from "zustand";
import { useQuery } from "@tanstack/react-query";
import { debounce } from "@mui/material";
import { api } from "api";
import { useFetchStateHelper } from "hooks/useFetchState";
import { usePersistentFetch } from "../utils/usePersistentFetch";

const useStore = create((set) => ({
  filters: {
    startDate: null,
    endDate: null,
    page: 1,
    categories: [],
    areas: [],
    crops: [],
  },
  allFilters: null,
  pagination: {
    page: 1,
    totalPages: 1,
  },
  setFilters: (filters) =>
    set((state) => ({
      ...state,
      filters: { ...state.filters, ...filters, page: 1 },
    })),
  changePage: (page) =>
    set((state) => ({ ...state, filters: { ...state.filters, page } })),
  setAllFilters: (allFilters) => set({ allFilters }),
  setPagination: (pagination) =>
    set((state) => ({
      ...state,
      pagination: { ...state.pagination, ...pagination },
    })),
}));

const useFeedFilters = () => {
  const filters = useStore((state) => state.filters);
  const setFilters = useStore((state) => state.setFilters);
  const allFilters = useStore((state) => state.allFilters);
  const setAllFilters = useStore((state) => state.setAllFilters);

  const { fetchState } = usePersistentFetch({
    queryKey: ["all-filters"],
    fetchFn: api.newsFeed.fetchFilters,
    setStoreData: setAllFilters,
    isFetched: !!allFilters,
  });

  return { allFilters, fetchState, filters, setFilters };
};

const fetchFeatured = async () => {
  const { data } = await api.newsFeed.fetchFeatured();
  return data;
};

const useFeaturedArticles = () => {
  const { data: featured, ...queryResponse } = useQuery({
    queryKey: ["featured-feed"],
    queryFn: fetchFeatured,
  });
  const { fetchState } = useFetchStateHelper(queryResponse);

  return { featured, fetchState };
};

const fetchArticles = async (filters) => {
  const setPagination = useStore.getState().setPagination;
  setPagination({ page: filters.page });
  const { data } = await api.newsFeed.fetchArticles(filters);
  setPagination({ page: data.page, totalPages: data.totalPages });
  return data;
};

const useArticles = () => {
  const filters = useStore((state) => state.filters);
  const pagination = useStore((state) => state.pagination);
  const changePage = debounce(useStore((state) => state.changePage, 300));

  const { data: articles, ...queryResponse } = useQuery({
    queryKey: ["articles-feed", filters],
    queryFn: () => fetchArticles(filters),
  });
  const { fetchState } = useFetchStateHelper(queryResponse);

  return { articles: articles?.results, pagination, fetchState, changePage };
};

export { useFeaturedArticles, useFeedFilters, useArticles };
