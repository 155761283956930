export class BiocycleDto {
  static setup(data) {
    return data?.map((cycle) => {
      const {
        id,
        actualPeriodStart,
        actualPeriodEnd,
        estimatedPeriodStart,
        estimatedPeriodEnd,
      } = cycle;
      const startYear = new Date(actualPeriodStart).getFullYear();
      const endYear = new Date(actualPeriodEnd).getFullYear();
      return {
        id,
        actualStart: actualPeriodStart,
        actualEnd: actualPeriodEnd,
        estimatedStart: estimatedPeriodStart,
        estimatedEnd: estimatedPeriodEnd,
        name: `${startYear} - ${endYear}`,
      };
    });
  }
}
