import moment from "moment";
import { isEn } from "i18next-config";

function readImageBase64(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const src = `data:image/jpeg;base64,${file}`;
    img.src = src;
    img.onerror = () => {
      reject("error");
    };
    img.onload = () => {
      resolve(src);
    };
  });
}

const PLACEHOLDER_IMAGES = Array.from(
  { length: 5 },
  (_, i) => `/assets/agronewsgeneric0${i + 1}.webp`
);

async function transformArticle(article) {
  let image;
  try {
    image = await readImageBase64(article.picture);
  } catch (err) {
    const randomImageUrl =
      PLACEHOLDER_IMAGES[Math.floor(Math.random() * PLACEHOLDER_IMAGES.length)];
    image = randomImageUrl;
  }
  return {
    id: article.id,
    title: article.title,
    shortDescription: article.shortDescription,
    url: article.url,
    tags: article.lTags
      ?.filter((tag) => tag.lTagName !== "featured")
      .map((tag) => ({
        value: tag.tagId,
        label: isEn ? tag.lTagName : tag.lTagNameGr,
      })),
    date: moment(article.dateTime).format("DD/MM/YYYY"),
    image,
  };
}

const filtersFromApi = {
  Category: "categories",
  GeoGRNuts2: "areas",
  Crop: "crops",
};
const filtersToApi = {
  categories: "category",
  areas: "geoGRNuts2",
  crops: "crop",
};

export class NewsFeedDTO {
  static filters(data) {
    const filters = {};
    for (const filter of data) {
      const filterTypeName = filter.typeName;
      const filterName = filtersFromApi[filterTypeName];
      if (!filterName) {
        continue;
      }
      filters[filterName] = {
        id: filter.id,
        value: filterName,
        label: filterName,
        options: filter.tags.map((tag) => ({
          value: tag.id,
          label: isEn ? tag.tagName : tag.tagNameGr,
        })),
      };
    }
    return filters;
  }

  static filterParams(filters) {
    const filtersForApi = {
      pageSize: 10,
    };
    for (const key in filters) {
      const valueExists = Boolean(filters[key]);
      if (!valueExists) {
        continue;
      }
      if (key in filtersToApi) {
        filtersForApi[filtersToApi[key]] = filters[key];
      } else {
        filtersForApi[key] = filters[key];
      }
    }
    return filtersForApi;
  }

  static async articles(data) {
    const page = data.currentPage;
    const totalPages = data.totalPages;
    const results = data.results;

    const finalArticles = [];
    for (const result of results) {
      const featured = await transformArticle(result);
      finalArticles.push(featured);
    }

    return {
      page,
      totalPages,
      results: finalArticles,
    };
  }

  static async featured(data) {
    const finalFeatured = [];
    const results = data.results;
    for (const result of results) {
      const featured = await transformArticle(result);
      finalFeatured.push(featured);
    }
    return finalFeatured;
  }
}
