import { useTranslation } from "react-i18next";
import { useActionChoices } from "stores";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { FormSelectInput } from "form";
import Title from "../../../Typography/Title";
import DateSpan from "../date-span/DateSpan";
import DetailSection from "../DetailSection";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";

const DiagnoseSuggestion = ({ viewMode, isProposedValue }) => {
  const { t } = useTranslation();
  const { actions } = useActionChoices();

  const status = ACTION_STATUS.suggested;
  const operations = actions.diagnose?.operations;
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;
  return (
    <div className="action-modal-card action-details-box">
      <div className="details-half-width">
        <Title title={t("ActionModal.Details.Suggested")} />
        <DateSpan viewMode={viewMode} status={status} />
        <DetailSection title={t("ActionModal.DiagnoseType")} isRequired>
          <FormSelectInput
            name="diagnoseAction.operationTypeId"
            shouldHaveErrorBehaviorAfterSubmit
            options={operations?.map(
              ({ operationTypeName, operationTypeId }) => ({
                value: operationTypeId,
                label: operationTypeName,
              })
            )}
            isViewMode={isViewMode}
          />
        </DetailSection>
        <ActionModalDescriptionSection
          actionBaseName="diagnoseAction"
          isViewMode={isViewMode}
          isProposed={isProposedValue}
        />
      </div>
    </div>
  );
};

export default DiagnoseSuggestion;
