import * as Yup from "yup";

export const fieldInfoSchema = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("FieldInfo.Camvio.ErrorRequired")),
    wateringMechanism: Yup.string().required(
      t("FieldInfo.Camvio.ErrorRequired")
    ),
    plantationDate: Yup.string().nullable(),
    numberOfRoots: Yup.number().nullable(),
    taxonomyId: Yup.string().required(t("FieldInfo.Camvio.ErrorRequired")),
    cropId: Yup.string().required(t("FieldInfo.Camvio.ErrorRequired")),
    varietyId: Yup.string().required(t("FieldInfo.Camvio.ErrorRequired")),
  });
