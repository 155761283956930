import { camelToSnake } from "./camelSnakeTransformation";
import { deepTransformKeys } from "./transformObjectKeys";

const appendValue = (searchParams, key, value) => {
  if (Array.isArray(value)) {
    if (value.length > 0) {
      const listValue = value.join(",");
      searchParams.append(key, listValue);
    }
  } else {
    searchParams.append(key, value);
  }
};

export const createSearchParams = (params, exclude = []) => {
  const searchParams = new URLSearchParams();

  if (!params) return searchParams;
  if (Object.keys(params).length === 0) return searchParams;

  // make keys snake_case
  const snakeCaseParams = deepTransformKeys(params, camelToSnake, exclude);

  // populate the URLSearchParams object
  for (const [key, value] of Object.entries(snakeCaseParams)) {
    appendValue(searchParams, key, value);
  }

  return searchParams;
};
