import { ActionChoicesDto } from "api/dtos/ActionChoicesDto";
import { CropsDto } from "api/dtos/CropsDto";
import { WateringMethodsDto } from "api/dtos/WateringMethodsDto";
import { StressTypesDto } from "api/dtos/StressTypesDto";
import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getAllActionChoices = async () => {
  try {
    const res = await axiosInstance.get(
      `${URLS.externalRequests}/action-choices/`
    );
    const actionChoices = ActionChoicesDto.setup(res.data);
    return { ...res, data: actionChoices };
  } catch (err) {
    console.error(err);
    throw err;
  }
};
const getCropVarieties = async () => {
  try {
    const res = await axiosInstance.get(`${URLS.cropVarieties}/`);
    const crops = CropsDto.setup(res.data);
    return { ...res, data: crops };
  } catch (err) {
    throw err;
  }
};

const getWateringMethods = async () => {
  try {
    const res = await axiosInstance.get(URLS.wateringMechanism);
    const methods = WateringMethodsDto.setup(res.data);
    return { ...res, data: methods };
  } catch (err) {
    throw err;
  }
};

const getAllBiocyclePhasesStresses = (fieldId, options = {}) =>
  axiosInstance.get(
    `${URLS.externalRequests}/agro/crops/get-biocyclephases-stresses/?field_id=${fieldId}`,
    options
  );

const getStressTypes = async () => {
  try {
    const res = await axiosInstance.get(
      `${URLS.externalRequests}/agro/stresses/get-stress-types/`,
      {}
    );
    const types = StressTypesDto.setup(res.data);
    return { ...res, data: types };
  } catch (err) {
    throw err;
  }
};

export const generalAgroApi = {
  actionChoices: getAllActionChoices,
  biocyclePhasesStresses: getAllBiocyclePhasesStresses,
  cropsInformation: getCropVarieties,
  stressTypes: getStressTypes,
  wateringMethods: getWateringMethods,
};
