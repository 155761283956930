import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useActionChoices } from "stores";
import { FormDateInput, FormSelectInput } from "form";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import Title from "../../Typography/Title";

const AnalysisBaseInfo = ({ viewMode = "view" }) => {
  const { t } = useTranslation();
  const { actions } = useActionChoices();
  const { setFieldValue, validateForm } = useFormikContext();
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  const analysisOptions = useMemo(() => {
    return [
      {
        value: actions.diagnose?.operations[1]?.operationTypeId,
        label: actions.diagnose?.operations[1]?.operationTypeName,
      },
      {
        value: actions.diagnose?.operations[2]?.operationTypeId,
        label: actions.diagnose?.operations[2]?.operationTypeName,
      },
    ];
  }, [actions]);

  const handleDateChange = (moment) => {
    const dateString = moment.toISOString();
    setFieldValue("datetimePerformed", dateString).then(() => {
      validateForm();
    });
    setFieldValue("datetimePerformedEnd", dateString).then(() => {
      validateForm();
    });
  };

  return (
    <div className="action-top-box-section">
      <Title title={t("ActionModal.AnalysisType")} />
      <FormSelectInput
        name="diagnoseAction.operationTypeId"
        placeholder={t("ActionModal.AnalysisTypeFull")}
        shouldHaveErrorBehaviorAfterSubmit
        isViewMode={isViewMode}
        options={analysisOptions}
      />
      <Title title={t("ActionModal.AnalysisDate")} />
      <FormDateInput
        name="datetimePerformed"
        onChange={handleDateChange}
        shouldHaveErrorBehaviorAfterSubmit
        isViewMode={isViewMode}
      />
    </div>
  );
};

export default AnalysisBaseInfo;
