import { create } from "zustand";
import { api } from "api";
import { usePersistentFetch } from "../utils/usePersistentFetch";

const useStore = create((set) => ({
  opekepeCultivars: null,
  isFetched: false,
  setOpekepeCultivars: (opekepeCultivars) =>
    set({ opekepeCultivars, isFetched: true }),
}));

const useOpekepeCultivars = () => {
  const setOpekepeCultivars = useStore((state) => state.setOpekepeCultivars);
  const opekepeCultivars = useStore((state) => state.opekepeCultivars);
  const isFetched = useStore((state) => state.isFetched);

  const { fetchState } = usePersistentFetch({
    isFetched,
    setStoreData: setOpekepeCultivars,
    fetchFn: api.opekepe.fetchCultivars,
    queryKey: "opekepeCultivars",
  });

  return { opekepeCultivars, fetchState };
};

export { useOpekepeCultivars };
