import { create } from "zustand";
import { api } from "api";
import { usePersistentFetch } from "../utils/usePersistentFetch";

const useStore = create((set) => ({
  wateringMethods: [],
  isFetched: false,
  setWateringMethods: (wateringMethods) =>
    set({ wateringMethods, isFetched: true }),
}));

const useWateringMethods = () => {
  const setWateringMethods = useStore((state) => state.setWateringMethods);
  const wateringMethods = useStore((state) => state.wateringMethods);
  const isFetched = useStore((state) => state.isFetched);

  const { fetchState } = usePersistentFetch({
    isFetched,
    setStoreData: setWateringMethods,
    fetchFn: api.generalAgro.wateringMethods,
    queryKey: "wateringMethods",
  });

  return { wateringMethods, fetchState };
};

export { useWateringMethods };
