import { useCallback } from "react";
import { create } from "zustand";
import { api } from "api";
import { sortAlphabetically } from "utils/utils";
import { usePersistentFetch } from "../utils/usePersistentFetch";

const useStore = create((set) => ({
  locations: [],
  isFetched: false,
  setLocations: (locations) => set({ locations, isFetched: true }),
}));

const useLocations = () => {
  const setLocations = useStore((state) => state.setLocations);
  const locations = useStore((state) => state.locations);
  const isFetched = useStore((state) => state.isFetched);

  const { fetchState } = usePersistentFetch({
    isFetched,
    setStoreData: setLocations,
    fetchFn: api.account.availableLocations,
    queryKey: "locations",
  });

  const getAllRegions = useCallback(
    (isMinimal) => {
      let result = [];
      if (isMinimal) {
        result = locations?.map((region) => {
          const { children, ...restRegionInfo } = region;
          return restRegionInfo;
        });
      } else {
        result = locations ?? result;
      }
      return sortAlphabetically(result, "label");
    },
    [locations]
  );
  const getAllPrefectures = useCallback(
    (isMinimal) => {
      let result;
      if (isMinimal) {
        result = locations?.reduce(
          (acc, region) =>
            acc.concat(
              region.children.map((prefecture) => {
                const { children, ...restPrefectureInfo } = prefecture;
                return restPrefectureInfo;
              })
            ),
          []
        );
      } else {
        result = locations?.reduce(
          (acc, region) => acc.concat(region.children),
          []
        );
      }
      return sortAlphabetically(result, "label");
    },
    [locations]
  );
  const getAllMunicipalities = useCallback(() => {
    const prefectures = getAllPrefectures();
    let result;
    result = prefectures?.reduce((acc, prefecture) => {
      return acc.concat(prefecture.children);
    }, []);
    return sortAlphabetically(result, "label");
  }, [getAllPrefectures]);

  const getPrefecturesOfRegion = useCallback(
    (regionId, isMinimal) => {
      const region = locations?.find((location) => location.value === regionId);
      if (!region) {
        return [];
      }
      let result;
      if (isMinimal) {
        result = region.children.map((prefecture) => {
          const { children, ...restPrefectureInfo } = prefecture;
          return restPrefectureInfo;
        });
      } else {
        result = region.children;
      }
      return sortAlphabetically(result, "label");
    },
    [locations]
  );
  const getMunicipalitiesOfPrefecture = useCallback(
    (prefectureId) => {
      const prefectures = getAllPrefectures();
      const prefecture = prefectures?.find(
        (prefecture) => prefecture.value === prefectureId
      );
      const municipalities = prefecture?.children || [];
      return sortAlphabetically(municipalities, "label");
    },
    [getAllPrefectures]
  );

  const getRegionById = useCallback(
    (regionId, isMinimal) => {
      let result = locations?.find((location) => location.value === regionId);
      if (isMinimal) {
        const { children, ...restRegionInfo } = result;
        result = restRegionInfo;
      }
      return result;
    },
    [locations]
  );

  const getPrefectureById = useCallback(
    (prefectureId, isMinimal) => {
      if (!locations) {
        return;
      }
      let result;
      for (let location of locations) {
        const prefectures = location.children;
        const prefecture = prefectures.find(
          (prefecture) => prefecture.value === prefectureId
        );
        if (prefecture) {
          result = prefecture;
          break;
        }
      }
      if (isMinimal) {
        const { children, ...restPrefectureInfo } = result;
        result = restPrefectureInfo;
      }
      return result;
    },
    [locations]
  );

  return {
    locations,
    fetchState,
    getAllRegions,
    getAllPrefectures,
    getAllMunicipalities,
    getPrefecturesOfRegion,
    getMunicipalitiesOfPrefecture,
    getRegionById,
    getPrefectureById,
  };
};

export { useLocations };
