import { LanguagesDto } from "api/dtos/LanguagesDto";
import { USER_ROLES } from "../../constants/userRoles";
import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";
import { LocationsDto } from "api/dtos/LocationsDto";

const registerFCMToken = (token) => {
  return axiosInstance.post(`${URLS.users}/devices/`, {
    registrationId: token,
    type: "web",
  });
};

const deleteMyAccount = (role) => {
  let userRole;
  if (role === USER_ROLES.FARMER) {
    userRole = "farmer";
  } else if (role === USER_ROLES.AGRONOMIST) {
    userRole = "agronomist";
  }

  return axiosInstance.delete(`${URLS.users}/${userRole}/profile/`);
};

const passwordRecovery = (payload) => {
  return axiosInstance.post(
    `${URLS.users}/password-recovery/request/`,
    payload
  );
};

const activateAccount = (payload) => {
  return axiosInstance.post(`${URLS.users}/activate-account/`, payload);
};

const changePassword = (payload) => {
  return axiosInstance.patch(`${URLS.users}/change-password/ `, payload, {});
};

const emailVerification = (payload) => {
  return axiosInstance.post(`${URLS.users}/send-email-verification/ `, payload);
};

const renewPassword = (payload) => {
  return axiosInstance.post(`${URLS.users}/password-recovery/renew/ `, payload);
};

const availableUserRegion = () => {
  return axiosInstance.get(`${URLS.users}/region/`);
};

const getMyProfile = (role) => {
  if (role === USER_ROLES.FARMER) {
    return axiosInstance.get(`${URLS.users}/farmer/profile/`);
  } else if (role === USER_ROLES.AGRONOMIST) {
    return axiosInstance.get(`${URLS.users}/agronomist/profile/`);
  }
};

const editUserProfile = ({ data, role }) => {
  if (role === USER_ROLES.FARMER) {
    return axiosInstance.patch(`${URLS.users}/farmer/profile/`, data);
  } else if (role === USER_ROLES.AGRONOMIST) {
    return axiosInstance.patch(`${URLS.users}/agronomist/profile/`, data);
  }
};

const getAvailableLanguages = async () => {
  try {
    const res = await axiosInstance.get(`${URLS.users}/language/`);
    const data = LanguagesDto.setup(res.data);
    return { ...res, data };
  } catch (error) {
    throw error;
  }
};
const getLocations = async () => {
  try {
    const res = await axiosInstance.get(`${URLS.search}/locations/`);
    const data = LocationsDto.setup(res.data);
    return { ...res, data };
  } catch (error) {
    throw error;
  }
};

export const accountApi = {
  activate: activateAccount,
  availableLanguages: getAvailableLanguages,
  availableLocations: getLocations,
  availableRegion: availableUserRegion,
  changePassword,
  delete: deleteMyAccount,
  emailVerification,
  fetch: getMyProfile,
  passwordRecovery,
  registerFCMToken,
  renewPassword,
  update: editUserProfile,
};
