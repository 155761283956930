import { useCallback, useMemo } from "react";
import { FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useWateringMethods } from "stores";
import { schemas } from "schemas";
import {
  ButtonCustom,
  CropSelector,
  LoadingButton,
  MapImage,
  SubTitle,
} from "components";
import MapContainer from "components/Maps/MapContainer";
import MapPolygon from "components/Maps/MapPolygon";
import { latlngFromArrayToLatLng } from "utils/convertToLatLng";
import {
  FormDateInput,
  FormNumberInput,
  FormSelectInput,
  FormTextInput,
} from "form";
import { useFieldInfoModalContext } from "context/FieldInfoModalProvider";
import Layout from "../../common/FieldInfoModalLayout";
import { InfoRow } from "../../common/Info";

const FieldCamvioInfoEdit = ({ setConfirmProps }) => {
  const { t } = useTranslation();

  const { field, updateField, setToViewMode } = useFieldInfoModalContext();
  const { wateringMethods } = useWateringMethods();

  const initialValues = useMemo(
    () => ({
      name: field?.name,
      numberOfRoots: field?.numberOfRoots || 0,
      plantationDate: field?.plantationDate,
      variety: field?.variety,
      wateringMechanism: field?.wateringMechanism,
      coordinates: field?.coordinates,
      cropId: field?.cropId,
      taxonomyId: field?.taxonomyId,
      varietyId: field?.varietyId,
    }),
    [field]
  );

  const handleSaveConfirmation = () => {
    setConfirmProps({
      isOpen: true,
      title: t("FieldInfo.Camvio.SaveChanges.Title"),
      contentText: t("FieldInfo.Camvio.SaveChanges.Text"),
      onConfirm: formik.handleSubmit,
    });
  };

  const handleSave = useCallback(
    (values) => {
      updateField({
        values,
        onSuccess: () => {
          setConfirmProps((prev) => ({ ...prev, isOpen: false }));
        },
        onError: () => {
          setConfirmProps((prev) => ({ ...prev, isOpen: false }));
        },
      });
    },
    [setConfirmProps, updateField]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSave,
    validationSchema: schemas.fieldInfo(t),
  });

  const handlePolygonChange = (polygon) => {
    const feature = polygon.toGeoJSON();
    const coords = feature.geometry.coordinates;
    formik.setFieldValue("coordinates", coords);
  };

  return (
    <FormikProvider value={formik}>
      <form
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Layout.InfoWrapperContainer>
          <Layout.MapViewContainer>
            <MapContainer>
              {field?.isOpekepeConnected ? (
                <MapImage
                  polygon={field?.coordsForMapImage}
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <MapPolygon
                  LatLngCoords={latlngFromArrayToLatLng(
                    formik.values.coordinates
                  )}
                  isEditable
                  onChange={handlePolygonChange}
                />
              )}
            </MapContainer>
          </Layout.MapViewContainer>
          <Layout.InfoContainer>
            <InfoRow>
              <FormTextInput
                name="name"
                label={t("FieldInfo.Camvio.FieldName")}
                style={{ flexBasis: "33%" }}
              />
              <div style={{ flexBasis: "33%" }}>
                <FormSelectInput
                  name="wateringMechanism"
                  label={t("FieldInfo.Camvio.WateringMethod")}
                  options={wateringMethods.map((method) => ({
                    value: method.id,
                    label: method.category,
                    info: method.description,
                  }))}
                />
              </div>
            </InfoRow>
            <div>
              <SubTitle
                text={t("FieldInfo.Camvio.Crop")}
                style={{ color: "#818181" }}
              />
              <InfoRow>
                <FormDateInput
                  name="plantationDate"
                  label={t("FieldInfo.Camvio.PlantationDate")}
                  isDisabled={field?.isOpekepeConnected}
                  style={{ width: "200px" }}
                />
                <FormNumberInput
                  name="numberOfRoots"
                  label={t("FieldInfo.Camvio.NumberOfRoots")}
                  isDisabled={field?.isOpekepeConnected}
                  style={{ width: "200px" }}
                />
              </InfoRow>
              <CropSelector
                isDisabled={field?.isOpekepeConnected}
                initialValues={{
                  cropId: initialValues?.cropId,
                  taxonomyId: initialValues?.taxonomyId,
                  varietyId: initialValues?.varietyId,
                }}
                style={{
                  flexDirection: "row",
                  gap: "24px",
                }}
                onChange={(cropVarietyCode) => {
                  formik.setFieldValue("variety", cropVarietyCode);
                }}
              />
            </div>
          </Layout.InfoContainer>
          <div style={{ display: "flex", alignSelf: "flex-end", gap: "12px" }}>
            <ButtonCustom variant="outlined" onClick={setToViewMode}>
              {t("Global.Cancel")}
            </ButtonCustom>
            <LoadingButton
              isDisabled={!formik.isValid}
              buttonType="filled"
              onClick={handleSaveConfirmation}
              style={{ alignSelf: "flex-end", width: "fit-content" }}
            >
              {t("FieldInfo.Camvio.Save")}
            </LoadingButton>
          </div>
        </Layout.InfoWrapperContainer>
      </form>
    </FormikProvider>
  );
};
export default FieldCamvioInfoEdit;
