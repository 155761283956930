import { useMemo, useState } from "react";
import { ConfirmationDialog, Loader } from "components";
import { FETCH_STATE } from "constants/fetchState";
import { useFieldInfoModalContext } from "context/FieldInfoModalProvider";
import { useUserContext } from "context/UserProvider";
import { fieldOwner } from "utils/extractInfoFromResponses";
import FieldCamvioInfoView from "./view/FieldCamvioInfoView";
import FieldCamvioInfoEdit from "./edit/FieldCamvioInfoEdit";

const INITIAL_CONFIRM_STATE = {
  isOpen: false,
  title: "",
  contentText: "",
  onConfirm: null,
};
const FieldCamvioInfo = () => {
  const [confirmProps, setConfirmProps] = useState(INITIAL_CONFIRM_STATE);

  const { field, actionState, isEditMode, fetchState } =
    useFieldInfoModalContext();

  const { userData } = useUserContext();
  const owner = useMemo(() => fieldOwner(field), [field]);
  const isMyField = userData?.id === owner?.userId;

  return (
    <>
      {fetchState === FETCH_STATE.LOADING && <Loader />}
      {fetchState === FETCH_STATE.SUCCESS &&
        field &&
        (isEditMode ? (
          <FieldCamvioInfoEdit setConfirmProps={setConfirmProps} />
        ) : (
          <FieldCamvioInfoView
            isMyField={isMyField}
            setConfirmProps={setConfirmProps}
          />
        ))}
      <ConfirmationDialog
        isOpen={confirmProps.isOpen}
        title={confirmProps.title}
        contentText={confirmProps.contentText}
        onConfirm={confirmProps.onConfirm}
        onCancel={() => setConfirmProps(INITIAL_CONFIRM_STATE)}
        isConfirmLoading={actionState === FETCH_STATE.LOADING}
      />
    </>
  );
};

export default FieldCamvioInfo;
