import { isEn } from "i18next-config";

export class StressTypesDto {
  static setup(data) {
    const types = data?.map((type) => {
      const { name, uuid, ...rest } = type;
      return {
        ...rest,
        id: uuid,
        name: isEn ? name.nameEn : name.nameGr,
        shortName: isEn
          ? name.nameEn?.split(" ")[0]
          : name.nameGr?.split(" ")[0],
      };
    });

    return types;
  }
}
