import { lazy, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import el from "moment/locale/el";
import ThemeConfig from "./configs/ThemeConfig.json";
import { storage } from "./utils/localStorage.js";

const ProtectedRoute = lazy(() => import("./layout/ProtectedRoute.jsx"));
const queryClient = new QueryClient();

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    moment.updateLocale("el", el);
  }, []);

  useEffect(() => {
    const handleLanguageChanged = (lan) => {
      moment.locale(lan);
    };

    if (i18n) {
      const language = storage.getLanguagePreference();
      i18n.changeLanguage(language);
      // TODO: - Change it to i18n.changeLanguage() to automatically detect the browser's language
      i18n.on("languageChanged", handleLanguageChanged);
      i18n.on("initialized", handleLanguageChanged);
    }
    return () => {
      if (i18n) {
        i18n.off("languageChanged", handleLanguageChanged);
        i18n.off("initialized", handleLanguageChanged);
      }
    };
  }, [i18n]);

  const theme = createTheme(ThemeConfig);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <ProtectedRoute />
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
