const TERMS_CONDITIONS_LINK = "https://www.camvio.gr/terms-of-use";
const PRIVACY_POLICY_LINK = "https://www.camvio.gr/privacy-policy";
const CAMVIO_WEBSITE = "https://www.camvio.ai";
const CAMVIO_MANUAL_LINK =
  "https://camvio.ai/wp-content/uploads/2024/12/camvio_manual_GR.pdf";
const EMAIL_SUPPORT = "support@camvio.gr";
const EMAIL_SALES = "sales@camvio.gr";
const PLAYSTORE_DOWNLOAD_APP_LINK =
  "https://play.google.com/store/apps/details?id=gr.dataverse.camvio.camvio_mobile_app";
const OPEKEPE_LOGIN_LINK = (isDev) => {
  return isDev
    ? "https://accounts.opekepe.gr/realms/eco-schemes/protocol/openid-connect/auth?response_type=code&client_id=camvio&redirect_uri=https%3A%2F%2Fdev.camvio.gr%2Fauth%2Fopekepe%2Fcallback%2F"
    : "https://accounts.opekepe.gr/realms/eco-schemes/protocol/openid-connect/auth?response_type=code&client_id=camvio&redirect_uri=https%3A%2F%2Fapp.camvio.gr%2Fauth%2Fopekepe%2Fcallback%2F";
};
export const LINKS = {
  EMAIL_SALES,
  EMAIL_SUPPORT,
  CAMVIO_WEBSITE,
  CAMVIO_MANUAL: CAMVIO_MANUAL_LINK,
  OPEKEPE_LOGIN: OPEKEPE_LOGIN_LINK,
  PLAYSTORE_DOWNLOAD_APP: PLAYSTORE_DOWNLOAD_APP_LINK,
  PRIVACY_POLICY: PRIVACY_POLICY_LINK,
  TERMS_CONDITIONS: TERMS_CONDITIONS_LINK,
};
