import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";
import { EmissionsDto } from "api/dtos/EmissionsDto";

const getCarbonFootprint = ({ fieldId, dateRange } = {}) => {
  if (!fieldId || !dateRange) return Promise.reject("Invalid params");

  const params = EmissionsDto.setupParams({ fieldId, dateRange });
  return axiosInstance.get(`${URLS.statistics}/carbon-footprint/`, {
    params,
  });
};

const getWaterConsumption = ({ fieldId, dateRange } = {}) => {
  const params = EmissionsDto.setupParams({ fieldId, dateRange });
  return axiosInstance.get(`${URLS.statistics}/irrigation-usage/`, {
    params,
  });
};

export const emissionsApi = {
  fetchCarbonFootprint: getCarbonFootprint,
  fetchWaterConsumption: getWaterConsumption,
};
