import { create } from "zustand";
import { usePersistentFetch } from "../utils/usePersistentFetch";
import { api } from "api";

const useStore = create((set) => ({
  stressTypes: [],
  isFetched: false,
  setStressTypes: (stressTypes) => set({ stressTypes, isFetched: true }),
}));

const useStressTypes = () => {
  const setStressTypes = useStore((state) => state.setStressTypes);
  const stressTypes = useStore((state) => state.stressTypes);
  const isFetched = useStore((state) => state.isFetched);

  const { fetchState } = usePersistentFetch({
    isFetched,
    setStoreData: setStressTypes,
    fetchFn: api.generalAgro.stressTypes,
    queryKey: "stressTypes",
  });

  return { stressTypes, fetchState };
};

export { useStressTypes };
