export const fieldFarmerName = ({ field }) => {
  const { agronomistDetails, farmerDetails } = field;

  let fullName = "";
  if (Array.isArray(farmerDetails) && farmerDetails.length) {
    fullName = userFullName({ user: farmerDetails[0] });
  } else if (Array.isArray(agronomistDetails) && agronomistDetails.length) {
    fullName = fullName = userFullName({ user: agronomistDetails[0] });
  }

  return fullName;
};

export const fieldCropVarietyName = ({ isEn, field }) => {
  let cropVarietyName = isEn
    ? (field?.varietyRead?.cropNameEn || "") +
      " " +
      (field?.varietyRead?.varietyNameEn || "")
    : (field?.varietyRead?.cropNameGr || "") +
      " " +
      (field?.varietyRead?.varietyNameGr || "");

  return cropVarietyName;
};

export const fieldVarietyName = ({ isEn, field }) => {
  let varietyName = isEn
    ? field?.varietyRead?.varietyNameEn || ""
    : field?.varietyRead?.varietyNameGr || "";

  return varietyName;
};

export const fieldCropName = ({ isEn, field }) => {
  let cropName = isEn
    ? field?.varietyRead?.cropNameEn || ""
    : field?.varietyRead?.cropNameGr || "";

  return cropName;
};

/**
 *
 * @param {Object} param0
 * @param {Object} param0.user
 * @param {('last' | 'first')} param0.first
 * @returns
 */
export const userFullName = ({ user, first = "last" }) => {
  const firstName = user?.firstName ? user.firstName + " " : "";
  const lastName = user?.lastName ? user.lastName + " " : "";

  const result =
    first === "first" ? firstName + lastName : lastName + firstName;
  return result.slice(0, -1);
};

export const userDomains = (user) => {
  const domains = user?.domains ?? [];
  if (!domains.length || !Array.isArray(domains)) {
    return "";
  }
  if (typeof domains[0] === "string") {
    return user?.domains?.join(", ");
  } else {
    return user?.domains?.map((domain) => domain.name).join(", ");
  }
};
