import { styled } from "@mui/material";
import { SmallText, SubTitle } from "components";

const InfoContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  paddingTop: "0.5rem",
  "& .info-label": {
    color: "#818181",
    fontWeight: 400,
    marginBottom: "2px",
  },
  "& .info-value": {
    color: "#577188",
  },
  "& .info-helper": {
    color: "#818181",
    marginTop: "4px",
    fontWeight: 400,
    fontStyle: "italic",
  },
});
const InfoWrapper = ({
  label,
  children,
  className,
  shouldHaveAsterisk,
  helper,
}) => {
  return (
    <InfoContainer className={className}>
      {label && (
        <SmallText className="info-label">
          {`${label}${shouldHaveAsterisk ? "*" : ""}`}
        </SmallText>
      )}
      <div className="info-value">{children}</div>
      <SmallText text={helper} className="info-helper" />
    </InfoContainer>
  );
};

const Info = ({ label, value, children }) => {
  return (
    <InfoWrapper label={label}>
      {children ? children : <SubTitle>{value || "-"}</SubTitle>}
    </InfoWrapper>
  );
};

export const InfoRow = styled("div")({
  display: "flex",
  gap: "1.5rem",
  flexWrap: "wrap",
});
export default Info;
