import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { iconButtonClasses, inputBaseClasses } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import TextFieldCustom from "../TextFieldCustom/TextFieldCustom";

const customTextStyle = {
  [`& .${inputBaseClasses.root}`]: {
    "& input": {
      paddingRight: 0,
    },
    [`& .${iconButtonClasses.root}`]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};

const DateField = ({
  label,
  name,
  placeholder,
  isDisabled,
  value,
  defaultValue,
  onChange,
  onMonthChange,
  defaultMonth,
  isError,
  isLoading,
  helperText,
  inputFormat = "DD/MM/YYYY",
  className,
  isViewMode,
  minDate,
  maxDate,
  style,
  availableDates,
  inputComponent,
}) => {
  const { i18n } = useTranslation();
  const [dateValue, setDateValue] = useState(defaultValue || value || null);

  const handleDateChange = useCallback(
    (date) => {
      setDateValue(date);
      onChange?.(date);
    },
    [onChange]
  );

  useEffect(() => {
    if (!defaultValue) {
      setDateValue(value || null);
    }
  }, [value, defaultValue]);

  const disableDate = useCallback(
    (day) => {
      if (availableDates) {
        return !availableDates.includes(moment(day).format("YYYY-MM-DD"));
      } else {
        return false;
      }
    },
    [availableDates]
  );

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={i18n.language}
    >
      <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        disableMaskedInput
        value={dateValue}
        defaultCalendarMonth={defaultMonth}
        shouldDisableDate={disableDate}
        onChange={handleDateChange}
        onMonthChange={onMonthChange}
        disabled={isDisabled}
        loading={isLoading}
        inputFormat={inputFormat}
        renderInput={(params) => {
          return inputComponent ? (
            inputComponent?.(params)
          ) : (
            <TextFieldCustom
              {...params}
              inputProps={{
                ...params?.inputProps,
                placeholder,
                readOnly: true,
              }}
              name={name}
              isViewMode={isViewMode}
              label={label}
              isError={isError}
              helperText={helperText}
              isDisabled={isDisabled}
              className={className}
              style={{ ...customTextStyle, ...style }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DateField;
