import { useCallback, useEffect } from "react";
import { create } from "zustand";
import { useQuery } from "@tanstack/react-query";
import { api } from "api";
import { FETCH_STATE } from "constants/fetchState";
import { useFetchStateHelper } from "hooks/useFetchState";

const useStore = create((set) => ({
  machineries: null,
  fetchState: { status: FETCH_STATE.IDLE },
  setMachineries: (machineries) => set({ machineries }),
  setFetchState: (fetchState) => set({ fetchState }),
}));

const useMachineries = () => {
  const setMachineries = useStore((state) => state.setMachineries);
  const setFetchState = useStore((state) => state.setFetchState);
  const machineries = useStore((state) => state.machineries);
  const fetchState = useStore((state) => state.fetchState);

  const fetchAndStoreMachineries = useCallback(async () => {
    const { data } = await api.equipments.fetchOptions();
    setMachineries(data);
    return { data };
  }, [setMachineries]);

  const { refetch, ...restQueryInfo } = useQuery({
    queryKey: ["machineries"],
    queryFn: fetchAndStoreMachineries,
    enabled: false,
  });

  const { trigger } = useFetchStateHelper(restQueryInfo, setFetchState);

  useEffect(() => {
    if (refetch && trigger) {
      if (!machineries) {
        trigger();
        refetch();
      }
    }
  }, [machineries, refetch, trigger]);

  return { machineries, fetchState };
};
export { useMachineries };
