import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useActionChoices } from "stores";

const useAvailableQuantityUnits = (taskId, operationTypeId) => {
  const { t } = useTranslation();
  const { getActionById } = useActionChoices();

  const operations = useMemo(() => {
    if (!taskId) return [];
    const action = getActionById(taskId, true);
    return action.operations || [];
  }, [getActionById, taskId]);

  const [availableQuantityUnits, setAvailableQuantityUnits] = useState([]);

  useEffect(() => {
    if (t && Array.isArray(operations)) {
      // If operation Type Id is given (not drug action) then find the operation
      // otherwise select the first operation (case of drug action)
      const selectedOperation = operationTypeId
        ? operations.find(({ operationTypeId: id }) => id === operationTypeId)
        : operations[0];

      setAvailableQuantityUnits(
        selectedOperation?.operationUnits?.quantityUnits?.map((unit) => ({
          value: unit,
          label: t(`OperationUnits.${unit}`),
        })) || []
      );
    } else {
      setAvailableQuantityUnits([]);
    }
  }, [operations, operationTypeId, t]);

  return { availableQuantityUnits };
};

export default useAvailableQuantityUnits;
