import {
  populateActionChoicesActions,
  populateActionChoicesOperations,
  populateActionChoicesTypes,
} from "utils/actionChoicesPopulation";

export class ActionChoicesDto {
  static setup(data) {
    const actionTypes = populateActionChoicesTypes(data?.actionTypes);
    const actions = populateActionChoicesActions(data?.actions);
    const operations = populateActionChoicesOperations(actions);

    return {
      actionTypes,
      actions,
      operations,
    };
  }
}
