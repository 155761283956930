import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import moment from "moment";
import FormDateRangeInput from "form/FormDateRangeInput";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import DetailSection from "../../details/DetailSection";

const PerformedDateSpan = ({ viewMode }) => {
  const { t, i18n } = useTranslation();
  const { values, initialValues, setFieldValue, validateForm } =
    useFormikContext();

  const handleDateRangeChange = ({ start, end }) => {
    const startIsoString = start.toISOString();
    if (moment.isMoment(start)) {
      setFieldValue("datetimePerformed", startIsoString).then(() => {
        validateForm();
      });
    }
    if (moment.isMoment(end)) {
      setFieldValue("datetimePerformedEnd", end.toISOString()).then(() => {
        validateForm();
      });
    } else {
      if (!values.datetimePerformedEnd) {
        setFieldValue("datetimePerformedEnd", startIsoString).then(() => {
          validateForm();
        });
      } else {
        setFieldValue("datetimePerformedEnd", undefined).then(() => {
          validateForm();
        });
      }
    }
  };

  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  return (
    <DetailSection
      isRequired={!isViewMode}
      title={t("ActionModal.CompletionDate")}
    >
      <FormDateRangeInput
        startName="datetimePerformed"
        endName="datetimePerformedEnd"
        startPlaceholder={t("ActionModal.DateSpan.From")}
        endPlaceholder={t("ActionModal.DateSpan.To")}
        startDefaultValue={
          initialValues.datetimePerformed &&
          moment(initialValues.datetimePerformed).locale(i18n.language)
        }
        endDefaultValue={
          initialValues.datetimePerformedEnd &&
          moment(initialValues.datetimePerformedEnd).locale(i18n.language)
        }
        startValue={
          values.datetimePerformed &&
          moment(values.datetimePerformed).locale(i18n.language)
        }
        endValue={
          values.datetimePerformedEnd &&
          moment(values.datetimePerformedEnd).locale(i18n.language)
        }
        onChange={handleDateRangeChange}
        isViewMode={isViewMode}
        isDisabled={isViewMode}
        shouldHaveErrorBehaviorAfterSubmit
        maxDate={moment().endOf("day")}
      />
    </DetailSection>
  );
};

export default PerformedDateSpan;
