import { useCallback } from "react";
import { create } from "zustand";
import { api } from "api";
import { usePersistentFetch } from "../utils/usePersistentFetch";
import { sortAlphabetically } from "utils/utils";

const useStore = create((set) => ({
  cropVarieties: [],
  isFetched: false,
  setCropVarieties: (cropVarieties) => set({ cropVarieties, isFetched: true }),
}));

const useCropVarieties = () => {
  const cropVarieties = useStore((state) => state.cropVarieties);
  const isFetched = useStore((state) => state.isFetched);
  const setCropVarieties = useStore((state) => state.setCropVarieties);

  const { fetchState } = usePersistentFetch({
    isFetched,
    setStoreData: setCropVarieties,
    fetchFn: api.generalAgro.cropsInformation,
    queryKey: "cropVarieties",
  });

  const getTaxonomyById = useCallback(
    (id, isMinimal) => {
      let result = cropVarieties.find((taxonomy) => taxonomy.value === id);
      if (isMinimal) {
        const { children, ...restTaxonomyInfo } = result;
        result = restTaxonomyInfo;
      }
      return result;
    },
    [cropVarieties]
  );

  const getCropById = useCallback(
    (id, isMinimal) => {
      let result;
      for (let taxonomy of cropVarieties) {
        const crops = taxonomy.children;
        const crop = crops.find((crop) => crop.value === id);
        if (crop) {
          result = crop;
          break;
        }
      }
      if (isMinimal) {
        const { children, ...restCropInfo } = result;
        result = restCropInfo;
      }
      return result;
    },
    [cropVarieties]
  );

  const getAllTaxonomies = useCallback(
    (isMinimal) => {
      let result;
      if (isMinimal) {
        result = cropVarieties?.map((taxonomy) => {
          const { children, ...restTaxonomyInfo } = taxonomy;
          return restTaxonomyInfo;
        });
      } else {
        result = cropVarieties;
      }
      return sortAlphabetically(result, "label");
    },
    [cropVarieties]
  );
  const getAllCrops = useCallback(
    (isMinimal) => {
      let result;
      if (isMinimal) {
        result = cropVarieties?.reduce(
          (acc, taxonomy) =>
            acc.concat(
              taxonomy.children.map((crop) => {
                const { children, ...restCropInfo } = crop;
                return restCropInfo;
              })
            ),
          []
        );
      } else {
        result = cropVarieties?.reduce(
          (acc, taxonomy) => acc.concat(taxonomy.children),
          []
        );
      }
      return sortAlphabetically(result, "label");
    },
    [cropVarieties]
  );
  const getAllVarieties = useCallback(() => {
    const crops = getAllCrops();
    let result;
    result = crops?.reduce((acc, crop) => {
      return acc.concat(crop.children);
    }, []);
    return sortAlphabetically(result, "label");
  }, [getAllCrops]);

  const getCropsOfTaxonomy = useCallback(
    (taxonomyId, isMinimal) => {
      const taxonomy = cropVarieties.find((tax) => tax.value === taxonomyId);
      if (!taxonomy) {
        return [];
      }
      let result;
      if (isMinimal) {
        result = taxonomy.children.map((crop) => {
          const { children, ...restCropInfo } = crop;
          return restCropInfo;
        });
      } else {
        result = taxonomy.children;
      }
      return sortAlphabetically(result, "label");
    },
    [cropVarieties]
  );

  const getVarietiesOfCrop = useCallback(
    (cropId) => {
      const crops = getAllCrops();
      const crop = crops.find((crop) => crop.value === cropId);
      const varieties = crop?.children || [];
      return sortAlphabetically(varieties, "label");
    },
    [getAllCrops]
  );

  return {
    cropVarieties,
    fetchState,
    getTaxonomyById,
    getCropById,
    getAllTaxonomies,
    getAllCrops,
    getAllVarieties,
    getCropsOfTaxonomy,
    getVarietiesOfCrop,
  };
};

export { useCropVarieties };
