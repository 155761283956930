import { useCallback } from "react";
import { create } from "zustand";
import { api } from "api";
import { ACTION } from "constants/actions";
import { usePersistentFetch } from "../utils/usePersistentFetch";
import { checkIfEmpty } from "utils/utils";

const useStore = create((set) => ({
  actionTypes: {},
  actions: {},
  operations: {},
  isFetched: false,
  setActionChoices: (actionChoices) =>
    set({
      actionTypes: actionChoices?.actionTypes,
      actions: actionChoices?.actions,
      operations: actionChoices?.operations,
      isFetched: true,
    }),
}));

const useActionChoices = () => {
  const actionTypes = useStore((state) => state.actionTypes);
  const actions = useStore((state) => state.actions);
  const operations = useStore((state) => state.operations);
  const isFetched = useStore((state) => state.isFetched);
  const setActionChoices = useStore((state) => state.setActionChoices);

  const { fetchState } = usePersistentFetch({
    isFetched,
    setStoreData: setActionChoices,
    fetchFn: api.generalAgro.actionChoices,
    queryKey: "actionChoices",
  });

  const getActionById = useCallback(
    (id, isPopulated = false) => {
      if (checkIfEmpty(actions)) return;
      if (actions[ACTION.watering].taskId === id) {
        return isPopulated ? actions[ACTION.watering] : ACTION.watering;
      }
      if (actions[ACTION.drug].taskId === id) {
        return isPopulated ? actions[ACTION.drug] : ACTION.drug;
      }
      if (actions[ACTION.fertilize].taskId === id) {
        return isPopulated ? actions[ACTION.fertilize] : ACTION.fertilize;
      }
      if (actions[ACTION.diagnose].taskId === id) {
        return isPopulated ? actions[ACTION.diagnose] : ACTION.diagnose;
      }
      if (actions[ACTION.other].taskId === id) {
        return isPopulated ? actions[ACTION.other] : ACTION.other;
      }
      return;
    },
    [actions]
  );

  const getOperationById = useCallback(
    (id, isPopulated) => {
      if (checkIfEmpty(operations)) return;

      let operationName;
      let operation;
      Object.entries(operations).forEach(([opName, op]) => {
        if (id === op.operationTypeId) {
          operationName = opName;
          operation = op;
        }
      });
      return isPopulated ? operation : operationName;
    },
    [operations]
  );

  const isSpecificAction = useCallback(
    (actionName, id) => {
      if (checkIfEmpty(actions)) return;
      const action = actions[actionName];
      return id === action.taskId;
    },
    [actions]
  );

  const isSpecificOperation = useCallback(
    (operationName, id) => {
      if (checkIfEmpty(operations)) return;
      const operation = operations[operationName];
      return id === operation.operationTypeId;
    },
    [operations]
  );

  return {
    actionTypes,
    actions,
    operations,
    fetchState,
    getActionById,
    getOperationById,
    isSpecificAction,
    isSpecificOperation,
  };
};

export { useActionChoices };
