import { useFormikContext } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ACTION_STATUS } from "constants/actionStatus";
import FormDateRangeInput from "form/FormDateRangeInput";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import DetailSection from "../../details/DetailSection";

const SuggestedDateSpan = ({ viewMode, status }) => {
  const { t, i18n } = useTranslation();
  const { values, initialValues, setFieldValue } = useFormikContext();

  const handleDateRangeChange = ({ start, end }) => {
    if (moment.isMoment(start)) {
      setFieldValue("dateRange.lower", start.format("YYYY-MM-DD"));
    }
    if (moment.isMoment(end)) {
      setFieldValue("dateRange.upper", end.format("YYYY-MM-DD"));
    } else {
      if (end != null) {
        setFieldValue("dateRange.upper", end);
      }
    }
  };

  const shouldInputsInViewMode =
    viewMode === ACTION_VIEW_MODE.view ||
    (viewMode === ACTION_VIEW_MODE.edit && status === ACTION_STATUS.performed);

  return (
    <DetailSection
      title={t("ActionModal.AssignedDate")}
      isRequired={viewMode !== ACTION_VIEW_MODE.view}
    >
      <FormDateRangeInput
        startName="dateRange.lower"
        endName="dateRange.upper"
        startPlaceholder={t("ActionModal.DateSpan.From")}
        endPlaceholder={t("ActionModal.DateSpan.To")}
        startDefaultValue={
          initialValues.dateRange.lower &&
          moment(initialValues.dateRange.lower).locale(i18n.language)
        }
        endDefaultValue={
          initialValues.dateRange.upper &&
          moment(initialValues.dateRange.upper).locale(i18n.language)
        }
        startValue={
          values.dateRange.lower &&
          moment(values.dateRange.lower).locale(i18n.language)
        }
        endValue={
          values.dateRange.upper &&
          moment(values.dateRange.upper).locale(i18n.language)
        }
        onChange={handleDateRangeChange}
        isDisabled={shouldInputsInViewMode}
        isViewMode={shouldInputsInViewMode}
        shouldHaveErrorBehaviorAfterSubmit
      />
    </DetailSection>
  );
};

export default SuggestedDateSpan;
