import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useActionChoices } from "stores";
import { Loader } from "components";
import { useSelectedFieldContext } from "context/SelectedFieldProvider";
import { varietyFullName } from "utils/extractInfoFromResponses";
import Modal from "../Modals/Modal";
import AnalysisReadyContent from "./content/AnalysisReadyContent";

const actionModalBreakpoint = "@media (max-width:1000px)";

const AnalysisModal = ({ isOpen, onClose, type }) => {
  const isSmallScreen = useMediaQuery(actionModalBreakpoint);
  const { i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const { selectedField } = useSelectedFieldContext();
  const { fetchState } = useActionChoices();

  const title =
    selectedField?.name +
    " / " +
    varietyFullName(isEn, selectedField, { splitter: " / " });

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className={`action-modal action-modal-modal ${
        isSmallScreen ? "small-screen" : ""
      }`}
      onClose={handleClose}
    >
      {fetchState.isPending && <Loader size={50} />}
      {fetchState.isSuccess && (
        <AnalysisReadyContent
          selectedField={selectedField}
          onClose={handleClose}
          type={type}
        />
      )}
    </Modal>
  );
};

export default AnalysisModal;
