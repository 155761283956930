import moment from "moment";

export class EmissionsDto {
  static setupParams(params) {
    const { fieldId, dateRange } = params;
    const startDate = moment(dateRange.start).format("YYYY-MM-DD");
    const endDate = moment(dateRange.end).format("YYYY-MM-DD");
    const finalParams = {
      field_id: fieldId,
      date_range: `${startDate},${endDate}`,
    };
    return finalParams;
  }
}
