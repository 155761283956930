import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { api } from "api";
import { USER_ROLES } from "constants/userRoles";
import { userRoleRead as userRoleReadFn } from "utils/userRoleRead";
import { storage } from "utils/localStorage";
import { FETCH_STATE } from "constants/fetchState";

const UserContext = createContext({
  userData: {
    id: "",
    firstName: "",
    lastName: "",
    domains: [],
    phone: "",
    email: "",
    userRole: "",
    userRoleId: "",
    userRoleRead: "",
    taxNumber: "",
    region: "",
    biography: "",
    language: {
      language: "",
      languageMacro: "",
      id: "",
    },
  },
  updateUserData: ({ newData, onSuccess, onError }) => {},
  fetchState: FETCH_STATE.IDLE,
});

const SettingsContext = createContext({
  collaboration: {
    email: false,
    inApp: false,
    webhook: false,
    webpush: false,
    mobilepush: false,
  },
  activities: {
    sms: false,
    email: false,
    inApp: false,
    webhook: false,
    webpush: false,
    mobilepush: false,
  },
  announcements: {
    sms: false,
    email: false,
    inApp: false,
    webhook: false,
    webpush: false,
    mobilepush: false,
  },
  update: (settings) => {},
  fetchState: FETCH_STATE.IDLE,
});

const INITIAL_EMPTY_INTERESTS = {
  regionIds: [],
  prefectureIds: [],
  municipalityIds: [],
  taxonomyIds: [],
  cropIds: [],
  varietyIds: [],
};

const InterestsContext = createContext({
  interests: undefined,
  fetchState: FETCH_STATE.IDLE,
  update: (interests) => {},
});

const populateState = ({ data, setData, userRole, t, i18n }) => {
  const { id: userRoleId, user, region, biography } = data;

  const userRoleRead = userRoleReadFn(t, userRole);

  const userLanguage = user.language?.languageMacro;
  const savedBrowserLanguage = storage.getLanguagePreference();

  if (userLanguage !== savedBrowserLanguage) {
    const lang = userLanguage || savedBrowserLanguage;
    i18n.changeLanguage(lang);
  }

  setData({
    id: "" + user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    domains: user.domains,
    dob: user.dob,
    language: user.language,
    phone: user.phone,
    email: user.email,
    userRole,
    userRoleId,
    taxNumber: user.taxNumber,
    userRoleRead,
    region,
    biography,
  });
};

/**
 * @param {Object} data
 * @param {Function} data.onError error handler
 * @returns
 */
const getRoleFromLocalStorage = (data) => {
  const { onError } = data || {};
  const { roles } = storage.getCredsFromLocalStorage();
  let userRole;
  if (!roles?.length) {
    onError?.();
    return;
  }
  if (roles.includes(USER_ROLES.FARMER)) {
    userRole = USER_ROLES.FARMER;
  } else if (roles.includes(USER_ROLES.AGRONOMIST)) {
    userRole = USER_ROLES.AGRONOMIST;
  }
  return userRole;
};

const populateInterests = (interests) => ({
  regionIds: interests.regionIds,
  prefectureIds: interests.prefectureIds,
  municipalityIds: interests.municipalityIds,
  taxonomyIds: interests.taxonomyIds,
  cropIds: interests.cropIds,
  varietyIds: interests.varietyIds,
});

const UserProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const [userData, setUserData] = useState();
  const [userFetchState, setUserFetchState] = useState(FETCH_STATE.IDLE);
  const [settings, setSettings] = useState();
  const [settingsFetchState, setSettingsFetchState] = useState(
    FETCH_STATE.IDLE
  );
  const [interests, setInterests] = useState();
  const [hasInterests, setHasInterests] = useState(false);
  const [interestsFetchState, setInterestsFetchState] = useState(
    FETCH_STATE.IDLE
  );
  const fetchUserData = useCallback(() => {
    setUserFetchState(FETCH_STATE.LOADING);

    const userRole = getRoleFromLocalStorage({
      onError: () => setUserFetchState(FETCH_STATE.ERROR),
    });

    api.account
      .fetch(userRole)
      .then((res) => {
        populateState({
          data: res.data,
          setData: setUserData,
          userRole,
          t,
          i18n,
        });
        setUserFetchState(FETCH_STATE.SUCCESS);
      })
      .catch((error) => {
        setUserFetchState(FETCH_STATE.ERROR);
      });
  }, [t, i18n]);

  const updateUserData = useCallback(
    ({ newData, onSuccess, onError }) => {
      const { id, email, biography, region, domains, ...restData } = newData;

      const finalData = {
        biography: biography || userData.biography,
        region: region || userData.region,
        user: restData,
      };

      api.account
        .update({ data: finalData, role: userData?.userRole })
        .then((res) => {
          populateState({
            data: res.data,
            setData: setUserData,
            t,
            userRole: userData?.userRole,
            i18n,
          });
          onSuccess?.(res.data);
        })
        .catch((err) => {
          onError?.(err);
        });
      return;
    },
    [t, userData?.userRole, userData?.biography, userData?.region, i18n]
  );

  const fetchSettings = useCallback(() => {
    setSettingsFetchState(FETCH_STATE.LOADING);

    api.settings.notifications
      .fetch()
      .then((res) => {
        setSettings(res.data);
        setSettingsFetchState(FETCH_STATE.SUCCESS);
      })
      .catch(() => {
        setSettingsFetchState(FETCH_STATE.ERROR);
      });
  }, []);

  const updateSettings = useCallback(async (settings) => {
    setSettingsFetchState(FETCH_STATE.LOADING);
    try {
      const res = await api.settings.notifications.update(settings);
      setSettings(res.data);
      setSettingsFetchState(FETCH_STATE.SUCCESS);
    } catch (err) {
      setSettingsFetchState(FETCH_STATE.ERROR);
    }
  }, []);

  const fetchInterests = useCallback(async () => {
    setInterestsFetchState(FETCH_STATE.LOADING);
    try {
      const res = await api.settings.interests.fetch();
      setHasInterests(true);
      setInterests(res.data);
      setInterestsFetchState(FETCH_STATE.SUCCESS);
    } catch (err) {
      if (err.response.status === 404) {
        setHasInterests(false);
        setInterests(INITIAL_EMPTY_INTERESTS);
        setInterestsFetchState(FETCH_STATE.SUCCESS);
      } else {
        setInterestsFetchState(FETCH_STATE.ERROR);
      }
    }
  }, []);

  const updateInterests = useCallback(
    ({ interests, onSuccess, onError }) => {
      if (hasInterests) {
        api.settings.interests
          .update(interests)
          .then((res) => {
            const newInterests = populateInterests(res.data);
            setInterests(newInterests);
            setHasInterests(true);
            onSuccess?.(newInterests);
          })
          .catch((err) => {
            onError?.(err);
          });
      } else {
        const newInterests = { ...INITIAL_EMPTY_INTERESTS, ...interests };
        api.settings.interests
          .post(newInterests)
          .then((res) => {
            const newInterests = populateInterests(res.data);
            setInterests(newInterests);
            setHasInterests(true);
            onSuccess?.(newInterests);
          })
          .catch((err) => {
            onError?.(err);
          });
      }
    },
    [hasInterests]
  );

  useEffect(() => {
    fetchUserData();
    fetchSettings();
    const userRole = getRoleFromLocalStorage();
    if (userRole && userRole === USER_ROLES.AGRONOMIST) {
      fetchInterests();
    }
  }, []);

  const userValue = useMemo(
    () => ({ userData, updateUserData, fetchState: userFetchState }),
    [userData, updateUserData, userFetchState]
  );

  const settingsValue = useMemo(
    () => ({
      settings,
      fetchState: settingsFetchState,
      update: updateSettings,
    }),
    [settings, updateSettings, settingsFetchState]
  );

  const interestsValue = useMemo(
    () => ({
      interests,
      fetchState: interestsFetchState,
      update: updateInterests,
    }),
    [interests, interestsFetchState, updateInterests]
  );

  return (
    <UserContext.Provider value={userValue}>
      <SettingsContext.Provider value={settingsValue}>
        <InterestsContext.Provider value={interestsValue}>
          {children}
        </InterestsContext.Provider>
      </SettingsContext.Provider>
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return useContext(UserContext);
};

const useSettingsContext = () => {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error("useSettingsContext must be used within a UserProvider");
  }

  return useContext(SettingsContext);
};

const useInterestsContext = () => {
  const context = useContext(InterestsContext);

  if (!context) {
    throw new Error("useInterestsContext must be used within a UserProvider");
  }

  return useContext(InterestsContext);
};

export {
  UserProvider,
  useUserContext,
  useSettingsContext,
  useInterestsContext,
};
