import { styled } from "@mui/material";

const ModalInner = styled("div")({
  height: "100%",
  padding: "1rem 1.2rem",
  paddingLeft: "1.5rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});

const InfoWrapperContainer = styled("div")({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  gap: "0.625rem",
  paddingRight: "0.25rem",
});
const MapViewContainer = styled("div")({
  height: "250px",
});
const InfoContainer = styled("div")({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  border: "solid 1px #e0e0e0",
  borderRadius: "8px",
  padding: "16px",
});

const Layout = {
  InfoWrapperContainer,
  InfoContainer,
  MapViewContainer,
  ModalInner,
};

export default Layout;
