import { isEn } from "i18next-config";

const transformLocations = ({ locations = [], parentName, parentIdPath }) => {
  if (!Array.isArray(locations)) {
    return [];
  }

  return locations.map((option) => {
    if (option.prefectures) {
      return {
        value: option.id,
        label: isEn ? option.nameEn : option.nameGr,
        children: transformLocations({
          locations: option.prefectures,
          isEn,
          parentIdPath: [option.id],
        }),
        parentIdPath: [],
      };
    } else if (option.municipalities) {
      const prefectureName = isEn ? option.nameEn : option.nameGr;
      return {
        value: option.id,
        label: isEn ? option.nameEn : option.nameGr,
        children: transformLocations({
          locations: option.municipalities,
          isEn,
          parentName: prefectureName,
          parentIdPath: [...parentIdPath, option.id],
        }),
        parentIdPath,
      };
    } else {
      return {
        value: option.id,
        label: isEn ? option.nameEn : option.nameGr + ", " + parentName,
        parentIdPath,
      };
    }
  });
};

export class LocationsDto {
  static setup(data) {
    const locations = transformLocations({ locations: data });
    return locations;
  }
}
