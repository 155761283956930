import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import {
  ALLOWED_ACTIONS,
  useFieldInfoModalContext,
} from "context/FieldInfoModalProvider";
import { ButtonCustom, MapImage, SubTitle } from "components";
import { paths } from "utils/paths";
import { toaster } from "utils/toaster";
import { fieldDimensionsRead } from "utils/fieldDimensions";
import Info, { InfoRow } from "../../common/Info";
import Layout from "../../common/FieldInfoModalLayout";

const FieldCamvioInfoView = ({ isMyField, setConfirmProps }) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const navigate = useNavigate();
  const { field, allow, deleteField, cancelCollaboration, setToEditMode } =
    useFieldInfoModalContext();
  const {
    id,
    ownerName,
    fieldName,
    cropVariety,
    wateringMethod,
    plantationDate,
    numberOfRoots,
    squareMeters,
    coordsForMapImage,
  } = field || {};

  const navigateToField = () => {
    navigate(paths.field(id));
  };

  const handleCancelCollaboration = () => {
    cancelCollaboration({
      onError: () => {
        toaster.error({
          message: t("FieldInfo.Camvio.CancelCollab.Error"),
        });
        setConfirmProps({
          isOpen: false,
        });
      },
    });
  };

  const handleDeleteField = () => {
    deleteField({
      onError: () => {
        toaster.error({
          message: t("FieldInfo.Camvio.DeleteField.Error"),
        });
        setConfirmProps({
          isOpen: false,
        });
      },
    });
  };

  const handleRemove = () => {
    setConfirmProps({
      isOpen: true,
      title: isMyField
        ? t("FieldInfo.Camvio.DeleteField.Title")
        : t("FieldInfo.Camvio.CancelCollab.Title"),
      contentText: isMyField
        ? t("FieldInfo.Camvio.DeleteField.Text")
        : t("FieldInfo.Camvio.CancelCollab.Text"),
      onConfirm: isMyField ? handleDeleteField : handleCancelCollaboration,
    });
  };

  const isRemoveAllowed =
    !field?.isOpekepeConnected && allow.includes(ALLOWED_ACTIONS.REMOVE);

  return (
    <Layout.InfoWrapperContainer>
      <Layout.MapViewContainer>
        <MapImage
          polygon={coordsForMapImage}
          style={{ width: "100%", height: "100%" }}
        />
      </Layout.MapViewContainer>
      <Layout.InfoContainer>
        <InfoRow>
          <Info label={t("FieldInfo.Camvio.FieldName")} value={fieldName} />
          <Info label={t("FieldInfo.Camvio.Owner")} value={ownerName} />
          <Info
            label={t("FieldInfo.Camvio.WateringMethod")}
            value={wateringMethod}
          />
        </InfoRow>
        <div>
          <SubTitle
            text={t("FieldInfo.Camvio.Crop")}
            style={{ color: "#818181" }}
          />
          <InfoRow>
            <Info
              label={t("FieldInfo.Camvio.PlantationDate")}
              value={plantationDate}
            />
            <Info
              label={t("FieldInfo.Camvio.CropVariety")}
              value={cropVariety}
            />
            <Info
              label={t("FieldInfo.Camvio.NumberOfRoots")}
              value={numberOfRoots}
            />
            <Info
              label={t("FieldInfo.Camvio.Area")}
              value={fieldDimensionsRead(t, isEn, squareMeters)}
            />
          </InfoRow>
        </div>
        {isRemoveAllowed && (
          <Button
            variant="outlined"
            onClick={handleRemove}
            sx={{
              border: "1px solid #c01c1c",
              color: "#c01c1c",
              textTransform: "none",
              width: "fit-content",
            }}
          >
            {isMyField
              ? t("FieldInfo.Camvio.DeleteField.Title")
              : t("FieldInfo.Camvio.CancelCollab.Title")}
          </Button>
        )}
      </Layout.InfoContainer>
      {allow.includes(ALLOWED_ACTIONS.EDIT) && (
        <ButtonCustom
          buttonType="filled"
          onClick={setToEditMode}
          style={{ alignSelf: "flex-end" }}
        >
          {t("FieldInfo.Camvio.Edit")}
        </ButtonCustom>
      )}
      {allow.includes(ALLOWED_ACTIONS.REDIRECT) && (
        <ButtonCustom onClick={navigateToField}>
          {t("FieldInfo.Camvio.View")}
        </ButtonCustom>
      )}
    </Layout.InfoWrapperContainer>
  );
};

export default FieldCamvioInfoView;
