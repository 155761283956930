import { URLS } from "api/utils/baseUrls";
import { OPEKEPE_SYNC_SCOPE } from "constants/opekepe";
import axiosInstance from "../utils/axiosInstance";
import { OpekepeDto } from "../dtos/OpekepeDto";

const syncAccount = async ({ code, scope }) => {
  try {
    const response = await axiosInstance.post(`${URLS.opekepeAuth}/`, {
      social: "opekepe",
      userCode: code,
      isKyd: scope === OPEKEPE_SYNC_SCOPE.KYD,
    });
    const opekepeSyncData = OpekepeDto.userSync(response.data.data);
    return { data: opekepeSyncData };
  } catch (error) {
    return { error };
  }
};

const getSyncedAccount = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `${URLS.opekepeAuth}/?user_id=${userId}`
    );
    const opekepeSyncData = OpekepeDto.userSync(response.data.data);
    return { data: opekepeSyncData };
  } catch (error) {
    return { error };
  }
};
const checkSyncUser = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `${URLS.opekepeAuth}/is-connected/?user_id=${userId}`
    );
    return { data: response.data };
  } catch (error) {
    return { error };
  }
};
const syncFields = async ({ userId, scope }) => {
  try {
    const isKyd = scope === OPEKEPE_SYNC_SCOPE.KYD;
    const response = await axiosInstance.post(
      `${URLS.opekepe}/applications/sync${isKyd ? "-kyd" : ""}/`,
      {
        year: "2024",
        initial: false,
        userId,
      }
    );
    const syncedFieldData = OpekepeDto.fieldsSync(response.data);
    return { data: syncedFieldData };
  } catch (error) {
    return { error };
  }
};

const checkSyncFieldsStatus = async (year) => {
  try {
    const response = await axiosInstance.get(`${URLS.opekepe}/applications/`);

    const syncedFieldStatus = OpekepeDto.checkIfSynced(response.data, year);
    return { data: syncedFieldStatus };
  } catch (error) {
    return { error };
  }
};

const getFieldOpekepeInfo = async ({ year, id }) => {
  try {
    const params = { year, field_id: id };
    const response = await axiosInstance.get(
      `${URLS.opekepe}/application-fields/`,
      { params }
    );
    const fieldOpekepeInfo = OpekepeDto.fieldOpekepeInfo(response.data);
    return { data: fieldOpekepeInfo };
  } catch (error) {
    return { error };
  }
};

const fetchCultivars = async () => {
  try {
    const response = await axiosInstance.get(`${URLS.opekepe}/cultivars/`);
    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

export const opekepeApi = {
  syncAccount,
  syncFields,
  checkSyncFieldsStatus,
  checkSyncUser,
  getSyncedAccount,
  fetchFieldInfo: getFieldOpekepeInfo,
  fetchCultivars,
};
