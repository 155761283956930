import { create } from "zustand";
import { api } from "api";
import { usePersistentFetch } from "../utils/usePersistentFetch";

const useStore = create((set) => ({
  languages: [],
  isFetched: false,
  setLanguages: (languages) => set({ languages, isFetched: true }),
}));

const useLanguages = () => {
  const setLanguages = useStore((state) => state.setLanguages);
  const languages = useStore((state) => state.languages);
  const isFetched = useStore((state) => state.isFetched);

  const { fetchState } = usePersistentFetch({
    isFetched,
    setStoreData: setLanguages,
    fetchFn: api.account.availableLanguages,
    queryKey: "languages",
  });

  return { languages, fetchState };
};

export { useLanguages };
