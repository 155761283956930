import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

const MenuItemIcon = ({ icon, iconComponent, iconClass }) => {
  const hasIcon = Boolean(iconComponent) || Boolean(iconClass) || Boolean(icon);

  return hasIcon ? (
    <ListItemIcon>
      {Boolean(icon) && (
        <div
          style={{
            width: "32px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={icon}
            style={{ width: "auto", height: "16px" }}
            alt="item-icon"
          />
        </div>
      )}
      {Boolean(iconComponent) && iconComponent}
      {Boolean(iconClass) && <i className={`icon ${iconClass}`} />}
    </ListItemIcon>
  ) : null;
};

const MenuDropdownItem = ({
  href,
  icon,
  iconClass,
  iconComponent,
  label,
  target,
  isDivider,
  onClick,
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick?.(e);
  };

  const isLink = Boolean(href);

  if (isDivider) {
    return <Divider style={{ margin: "4px" }} />;
  }
  return isLink ? (
    <Link
      style={{ textDecoration: "none", color: "inherit" }}
      to={href}
      target={target}
    >
      <MenuItem onClick={handleClick} dense>
        <MenuItemIcon
          icon={icon}
          iconComponent={iconComponent}
          iconClass={iconClass}
        />
        <ListItemText>{label}</ListItemText>
      </MenuItem>
    </Link>
  ) : (
    <MenuItem onClick={handleClick} dense>
      <MenuItemIcon
        icon={icon}
        iconComponent={iconComponent}
        iconClass={iconClass}
      />
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
};

export default MenuDropdownItem;
