import { useTranslation } from "react-i18next";
import Card from "../Card/Card";
import Loader from "../Loader/Loader";
import SubTitle from "../Typography/SubTitle";
import NormalText from "../Typography/NormalText";
import NoContentFound from "../NoContentFound/NoContentFound";
import Avatar from "./Avatar";
import "./UserCard.css";
import { styled } from "@mui/material";
import { useMemo } from "react";
import { userDomains, userFullName } from "utils/namingUtils";

const NameText = styled(SubTitle)({
  color: "#1B1D21",
});
const Label = styled(NormalText)({
  color: "#4D4D4D",
});
const ValueText = styled(NormalText)({
  color: "#1B1D21",
});
const CardContent = styled(Card.SectionContent)({
  paddingLeft: "1.5rem",
});

const UserCard = ({ isLoading, user, title }) => {
  const { t } = useTranslation();
  const finalTitle = title || t("UserCard.Title");
  const plan = useMemo(() => userDomains(user), [user]);
  const fullName = useMemo(
    () => userFullName({ user, first: "first" }),
    [user]
  );

  return (
    <Card style={{ minHeight: "250px", boxSizing: "border-box" }}>
      {isLoading ? (
        <Loader />
      ) : user ? (
        <>
          <Card.Section>
            <Card.SectionTitle title={finalTitle} />
            <CardContent>
              <div className="user-info-card-wrapper">
                <Avatar firstName={user.firstName} lastName={user.lastName} />
                <div className="user-info-card-name">
                  <NameText text={fullName} />
                  <div className="user-info-card-contact">
                    <div className="user-info-card-contact-column">
                      <Label text={t("UserCard.ContactPhone")} />
                      <ValueText text={user.phone} />
                    </div>
                    <div className="user-info-card-contact-column">
                      <Label text={t("UserCard.Email")} />
                      <ValueText text={user.email} />
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card.Section>
          <Card.Section>
            <Card.SectionTitle title={t("UserCard.ChargePlanInfo")} />
            <CardContent>{plan || t("UserCard.Free")}</CardContent>
          </Card.Section>
        </>
      ) : (
        <NoContentFound />
      )}
    </Card>
  );
};

export default UserCard;
