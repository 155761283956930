import { URLS } from "../utils/baseUrls";
import axiosInstance from "../utils/axiosInstance";
import { EquipmentsDto } from "../dtos/EquipmentsDto";

const getAllEquipments = () => axiosInstance.get(`${URLS.equipments}/`);

const getEquipment = (id) => axiosInstance.get(`${URLS.equipments}/${id}/`);
const getMachineryOptions = async () => {
  try {
    const res = await axiosInstance.get(
      `${URLS.machineryInfrastructure}/machineries/`
    );
    const machineries = EquipmentsDto.setup(res.data);
    return { ...res, data: machineries };
  } catch (err) {
    throw err;
  }
};

const addEquipment = (payload) =>
  axiosInstance.post(`${URLS.equipments}/`, payload);

const updateEquipment = (id, payload) =>
  axiosInstance.patch(`${URLS.equipments}/${id}/`, payload);

const deleteEquipment = (id) =>
  axiosInstance.delete(`${URLS.equipments}/${id}/`);

export const equipmentsApi = {
  fetchAll: getAllEquipments,
  fetchOne: getEquipment,
  fetchOptions: getMachineryOptions,
  add: addEquipment,
  update: updateEquipment,
  delete: deleteEquipment,
};
