import { useCallback, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useFetchStateHelper } from "hooks/useFetchState";

export const usePersistentFetch = ({
  isFetched,
  setStoreData,
  fetchFn,
  queryKey,
}) => {
  const fetchAndStoreData = useCallback(async () => {
    const res = await fetchFn();
    setStoreData?.(res.data);
    return res;
  }, [fetchFn, setStoreData]);

  const { refetch, data, ...restQueryInfo } = useQuery({
    queryKey: [queryKey],
    queryFn: fetchAndStoreData,
    enabled: false,
  });

  const { fetchState } = useFetchStateHelper(restQueryInfo);

  useEffect(() => {
    if (refetch) {
      if (!isFetched) {
        refetch();
      }
    }
  }, [isFetched, refetch]);

  return { fetchState, data };
};
