import { useCallback, useEffect, useMemo, useState } from "react";
import { FETCH_STATE } from "constants/fetchState";

export const useFetchState = (initialState) => {
  const [state, setState] = useState(initialState ?? FETCH_STATE.IDLE);
  const [error, setError] = useState();
  const isLoading = useMemo(() => state === FETCH_STATE.LOADING, [state]);
  const isSuccess = useMemo(() => state === FETCH_STATE.SUCCESS, [state]);
  const isError = useMemo(() => state === FETCH_STATE.ERROR, [state]);

  const setToLoading = useCallback(() => {
    setState(FETCH_STATE.LOADING);
  }, []);
  const setToError = useCallback((error) => {
    setError(error);
    setState(FETCH_STATE.ERROR);
  }, []);
  const setToSuccess = useCallback(() => {
    setState(FETCH_STATE.SUCCESS);
  }, []);

  return useMemo(
    () => ({
      state,
      error,
      isLoading,
      isSuccess,
      isError,
      setToLoading,
      setToError,
      setToSuccess,
    }),
    [
      state,
      error,
      isLoading,
      isSuccess,
      isError,
      setToLoading,
      setToError,
      setToSuccess,
    ]
  );
};

export const useFetchStateHelper = (
  { status, error, isPending, isError, isSuccess },
  setFetchState
) => {
  const [allowUpdates, setAllowUpdates] = useState(false);

  const trigger = useCallback(() => {
    setAllowUpdates(true);
  }, []);

  const fetchState = useMemo(
    () => ({
      error,
      isPending,
      isError,
      isSuccess,
      status,
    }),
    [error, status, isPending, isError, isSuccess]
  );

  useEffect(() => {
    if (allowUpdates) {
      setFetchState?.(fetchState);
    }
  }, [fetchState, allowUpdates, setFetchState]);

  return useMemo(() => ({ fetchState, trigger }), [fetchState, trigger]);
};

const withFetchState = (handler) => {
  return async (...args) => {
    const fetchState = useFetchState();
    fetchState.setToLoading();
    try {
      await handler(...args);
      fetchState.setToSuccess();
    } catch (error) {
      fetchState.setToError(error);
    }
  };
};
