import { KeyboardArrowUp } from "@mui/icons-material";
import { Fab, styled } from "@mui/material";
import { useEffect, useState } from "react";

const FabStyled = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: "2rem",
  right: "2rem",
  transition: "visibility 0.3s",
  [theme.breakpoints.down("md")]: {
    bottom: "4rem",
    width: "40px",
    height: "40px",
  },
}));

const ScrollToTopButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight - 200) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <FabStyled
      color="primary"
      onClick={scrollToTop}
      sx={{ visibility: showScroll ? "visible" : "hidden" }}
    >
      <KeyboardArrowUp
        sx={{ width: "100%", height: "100%", strokeWidth: "4px" }}
      />
    </FabStyled>
  );
};

export default ScrollToTopButton;
